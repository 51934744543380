import React, { Fragment, useRef, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { showWorkshop } from "../config"

import { LocaleContext } from "../index.js"
import * as actions from "../actions"

import {
	useFetchNextAgendaPage,
	useFetchAgenda,
	useFetchPreviousAgendaPage,
	useInitAgendaQueries,
	useFetchOnScroll,
	useInitAgendaWidth,
	useDeleteAbsenceModalToggling,
	useTimerangeDropdownClosing,
	useSubmitDeleteAbsence,
	useLastActiveTimerange,
	useSubmitAbsenceWithImpactedBookings,
	useSubmitImpactedBookingsActionsForOA,
	useWeekNavigationHandler,
	useLeaveWorkerFullMonth,
	useDeleteAbsenceWarningModalToggling,
} from "./AgendaHooks"
import { PageLayout, SmallSpinner } from "./FunctionalDesign"
import AgendaTopRow from "./AgendaTopRow"
import FagendaRow, { AgendaRowPlaceholder } from "./FagendaRow"
import AgendaNavigation from "./AgendaNavigation"
import DeleteAbsenceWarningModal from "./DeleteAbsenceWarningModal"
import ImpactedBookingsModal from "./ImpactedBookingsModal"
import { Arrow } from "../svg/arrow"

const Fagenda = ({ detachedMode = false, controls = null }) => {
	const t = useContext(LocaleContext)
	const dispatch = useDispatch()
	const data = useSelector(state => state.redData.agenda)
	const agenda = useSelector(state => state.redComponents.agenda)
	const impactedBookings = useSelector(state => state.redComponents.impactedBookingsModal)
	const currAffiliate = useSelector(state => state.redData.currentAffiliate)
	const currUser = useSelector(state => state.redData.currentUser)
	const currentQuery = useSelector(state => state.currentURLQuery)

	const fetchAgenda = useFetchAgenda()
	useInitAgendaQueries(fetchAgenda, detachedMode)
	const fetchNextPage = useFetchNextAgendaPage(fetchAgenda)
	const fetchPreviousPage = useFetchPreviousAgendaPage(fetchAgenda)
	const scrollWrapper = useRef(null)
	useFetchOnScroll(scrollWrapper, fetchNextPage, fetchPreviousPage)
	useInitAgendaWidth(scrollWrapper)

	const closeRangeDropdown = useTimerangeDropdownClosing()
	const toggleDeleteAbsenceModal = useDeleteAbsenceModalToggling(closeRangeDropdown)
	const toggleDeleteAbsenceWarningModal = useDeleteAbsenceWarningModalToggling(closeRangeDropdown)
	const getLastActiveTimerange = useLastActiveTimerange()
	const submitDeleteAbsence = useSubmitDeleteAbsence(
		getLastActiveTimerange,
		toggleDeleteAbsenceModal
	)

	let {
		fetching,
		showPreviousRowsSpinner,
		showNextRowsSpinner,
		fullMonthAffiliateWorkerId,
		activeTooltipRange,
		minutesWidth,
		activeWorkerDrop,
	} = agenda
	let { schedules, weekStart } = data

	let pageHeaderLeft = <h1 className="page-title agenda">{t("agenda")}</h1>
	const submitAbsenceWithImpactedBookings = useSubmitAbsenceWithImpactedBookings()
	const submitImpactedBookingsActionsForOA = useSubmitImpactedBookingsActionsForOA()
	const handleWeekChange = useWeekNavigationHandler(fetchAgenda)
	const leaveWorkerFullMonthMode = useLeaveWorkerFullMonth(fetchAgenda)
	let pageHeaderRight = (
		<div className="agenda-page-header-right">
			<AgendaNavigation
				leaveWorkerFullMonthMode={leaveWorkerFullMonthMode}
				handleWeekChange={handleWeekChange}
			/>
		</div>
	)
	let agendaMainContent = (
		<Fragment>
			{(fetching || !detachedMode || schedules.length !== 0) && (
				<AgendaTopRow
					data={data}
					agenda={agenda}
					currAffiliate={currAffiliate}
					currentQuery={currentQuery}
					fetchAgenda={fetchAgenda}
					showFilters={!detachedMode}
				/>
			)}
			<div className="agenda-scroll-wrapper" ref={scrollWrapper}>
				<div className="spinner-previous-wrapper">
					<SmallSpinner show={showPreviousRowsSpinner} />
				</div>
				{schedules.map((sched, index) => (
					<FagendaRow
						key={index}
						schedule={sched}
						currentQuery={currentQuery}
						fullMonthAffiliateWorkerId={fullMonthAffiliateWorkerId}
						activeTooltipRange={activeTooltipRange}
						secSocEvents={currAffiliate.secSocEvents}
						workshops={currAffiliate.workshops}
						weekStart={weekStart}
						minutesWidth={minutesWidth}
						activeWorkerDrop={activeWorkerDrop}
						fetchAgenda={fetchAgenda}
						currUser={currUser}
					/>
				))}
				{fetching && (
					<Fragment>
						<AgendaRowPlaceholder />
						<AgendaRowPlaceholder />
						<AgendaRowPlaceholder />
						<AgendaRowPlaceholder />
					</Fragment>
				)}
				<div className="spinner-next-wrapper">
					{!showNextRowsSpinner &&
						!fullMonthAffiliateWorkerId &&
						schedules.length !== 0 &&
						!fetching && <Arrow className="arrow-svg" onClick={fetchNextPage} />}
					<SmallSpinner show={showNextRowsSpinner} />
				</div>
			</div>
			<div className="legend">
				<div className="legend-item booking">{t("booking")}</div>
				<div className="legend-item booking ponctual">{t("ponctual_booking")}</div>
				<div className="legend-item booking ponctual replacement">
					{t("replacement_booking")}
				</div>
				<div className="legend-item absence">{t("absence")}</div>
				<div className="legend-item availability_cancellation">
					{t("availability_following_cancellation")}
				</div>
				<div className="legend-item daily_schedule">{t("availability")}</div>
				<div className="legend-item extra-avail">{t("extra_availability")}</div>
				<div className="legend-item affiliateHoliday">{t("holiday")}</div>
				<div className="legend-item other_activity">{t("other_activity")}</div>
				{showWorkshop && currAffiliate.workshopAddOn && (
					<div className="legend-item other_activity workshop_hour">
						{t("workshop_hour")}
					</div>
				)}
				<div className="legend-item theoretical_hour">{t("theoretical_hour")}</div>
				<div className="legend-item outsideContract">{t("outside_contract")}</div>
			</div>
		</Fragment>
	)
	return detachedMode ? (
		<div className="detached-agenda agenda-main" style={{ overflow: "initial" }}>
			{agendaMainContent}
			<div className="controls">{controls}</div>
		</div>
	) : (
		<PageLayout
			customClass="agenda-main"
			headerLeft={pageHeaderLeft}
			headerRight={pageHeaderRight}
			// onHeaderClick={this.closeAllDropdowns}
		>
			{agendaMainContent}
			{agenda.showDeleteAbsenceWarningModal && (
				<DeleteAbsenceWarningModal
					impactedOAs={impactedBookings.impactedOas}
					toggleModal={toggleDeleteAbsenceWarningModal}
					deleteAbsence={submitDeleteAbsence}
				/>
			)}
			{impactedBookings.showImpactedBookingsModal && (
				<ImpactedBookingsModal
					onSubmit={() => dispatch(actions.submitDirectBookingModification())}
					triggerActionExpected={actions.DIRECT_BOOKING_MODIFICATION_FAILURE}
				/>
			)}
			{impactedBookings.showImpactedBookingsModal && (
				<ImpactedBookingsModal
					onSubmit={() => dispatch(actions.submitCancelBooking())}
					triggerActionExpected={actions.CANCELBOOKING_FAILURE}
				/>
			)}
			{impactedBookings.showImpactedBookingsModal && (
				<ImpactedBookingsModal
					onSubmit={submitImpactedBookingsActionsForOA}
					triggerActionExpected={actions.SUBMIT_OTHER_ACTIVITY_FAILURE}
				/>
			)}
			{impactedBookings.showImpactedBookingsModal && (
				<ImpactedBookingsModal
					onSubmit={submitAbsenceWithImpactedBookings}
					triggerActionExpected={[
						actions.CREATEABSENCEFROMAGENDA_FAILURE,
						actions.UPDATEABSENCEFROMAGENDA_FAILURE,
					]}
				/>
			)}
		</PageLayout>
	)
}

export default Fagenda
