import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { ContentLayout, PootsySubHeading, PootsyButton, OptionBox } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import InputsChangeHandler from "./InputsChangeHandler"

import binIcon from "../static/images/bin-icon.png"

class SettingsSecSocMapping extends Component {
	state = {
		currentPootsyEvent: "",
		currentSecSocTimesheetCode: "",
		currentPootsyExpense: "",
		currentSecSocExpenseCode: "",
		currentExpenseTypeForCustomCode: "",
		currentCustomSecSocExpenseCode: "",
	}
	UNSAFE_componentWillMount = () => {
		this.fetchMappings()
		this.fetchCustomExpensesCodes()
		this.setState = this.setState.bind(this)
	}
	fetchMappings = () => {
		let { dispatch } = this.props
		let url = "/front/affiliates/social_secretary_term_mappings"
		let init = { method: "GET" }
		dispatch(actions.simpleFetch(url, init, "FETCH_SEC_SOC_MAPPINGS"))
	}
	fetchCustomExpensesCodes = () => {
		let { dispatch } = this.props
		let url = "/front/affiliates/custom_expense_codes"
		let init = { method: "GET" }
		dispatch(actions.simpleFetch(url, init, "FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES"))
	}
	submitEventMapping = () => {
		let { pootsyEvents, currAffiliate } = this.props
		let { currentPootsyEvent, currentSecSocTimesheetCode } = this.state
		if (!currentPootsyEvent || !currentSecSocTimesheetCode) {
			return
		}
		let pEvent = pootsyEvents.find(entry => entry === currentPootsyEvent)
		let ssCode = currAffiliate.secSocEvents.codes.find(
			entry => entry.id === currentSecSocTimesheetCode
		)
		this.setState({ currentPootsyEvent: "", currentSecSocTimesheetCode: "" })
		this.submit(pEvent, ssCode.id)
	}
	submitExpenseMapping = () => {
		let { pootsyExpenses, currAffiliate } = this.props
		let { currentPootsyExpense, currentSecSocExpenseCode } = this.state
		if (!currentPootsyExpense || !currentSecSocExpenseCode) {
			return
		}
		let pExpense = pootsyExpenses.find(entry => entry === currentPootsyExpense)
		let ssCode = currAffiliate.secSocEvents.codes.find(
			entry => entry.id === currentSecSocExpenseCode
		)
		this.setState({ currentPootsyExpense: "", currentSecSocExpenseCode: "" })
		this.submit(pExpense, ssCode.id)
	}
	handleSubmitCustomCodeClick = () => {
		let { currAffiliate } = this.props
		let { currentExpenseTypeForCustomCode, currentCustomSecSocExpenseCode } = this.state
		if (!currentExpenseTypeForCustomCode || !currentCustomSecSocExpenseCode) {
			return
		}
		let ssCode = currAffiliate.secSocEvents.codes.find(
			entry => entry.id === currentCustomSecSocExpenseCode
		)
		this.setState({ currentExpenseTypeForCustomCode: "", currentCustomSecSocExpenseCode: "" })
		this.submitCustomExpenseCode(currentExpenseTypeForCustomCode, ssCode.id)
	}
	removeSavedMapping = e => {
		let { dispatch } = this.props
		let { mappingid } = e.target.dataset
		let url = "/front/affiliates/social_secretary_term_mappings/" + mappingid
		let init = { method: "DELETE" }
		dispatch(
			actions.simpleFetch(url, init, "DELETE_SEC_SOC_TERM_MAPPING", {
				deletedID: parseInt(mappingid, 10),
			})
		)
	}
	removeSavedCustomExpenseCode = e => {
		let { dispatch } = this.props
		let { mappingid } = e.target.dataset
		let url = "/front/affiliates/custom_expense_codes/" + mappingid
		let init = { method: "DELETE" }
		dispatch(
			actions.simpleFetch(url, init, "DELETE_CUSTOM_EXPENSE_CODE", {
				deletedID: Number(mappingid),
			})
		)
	}
	submit = async (event, ssCode, extraFields) => {
		let { dispatch } = this.props
		let url = "/front/affiliates/social_secretary_term_mappings"
		let init = {
			method: "POST",
			body: JSON.stringify({
				event,
				social_secretary_code: ssCode,
				default_ef_values: extraFields,
			}),
		}
		let { actionType } = await dispatch(
			actions.simpleFetch(url, init, "SUBMIT_SEC_SOC_TERM_MAPPING")
		)
		if (actionType === actions.SUBMIT_SEC_SOC_TERM_MAPPING_SUCCESS) {
			let url = "/front/affiliates/affiliates"
			let init = { method: "GET" }
			dispatch(actions.simpleFetch(url, init, actions.FETCH_AFFILIATE))
		}
	}
	submitCustomExpenseCode = async (expenseType, ssCode) => {
		let { dispatch } = this.props
		let url = "/front/affiliates/custom_expense_codes"
		let init = {
			method: "POST",
			body: JSON.stringify({ expense_type: expenseType, social_secretary_code: ssCode }),
		}
		let { actionType } = await dispatch(
			actions.simpleFetch(url, init, "SUBMIT_CUSTOM_EXPENSE_CODE")
		)
		if (actionType === actions.SUBMIT_CUSTOM_EXPENSE_CODE_SUCCESS) {
			let url = "/front/affiliates/affiliates"
			let init = { method: "GET" }
			dispatch(actions.simpleFetch(url, init, actions.FETCH_AFFILIATE))
		}
	}
	render() {
		let { t } = this.context
		let {
			pootsyEvents,
			pootsyExpenses,
			currAffiliate,
			mappings,
			secSocCustomExpensesCodes,
		} = this.props
		let {
			currentPootsyEvent,
			currentPootsyExpense,
			currentSecSocTimesheetCode,
			currentSecSocExpenseCode,
			currentExpenseTypeForCustomCode,
			currentCustomSecSocExpenseCode,
		} = this.state
		let allFetched = pootsyEvents.length > 0
		let optionsBoxes = (
			<OptionBox boxTitle={t("settings_sec_soc_mapping_explanation_title")}>
				{t("settings_sec_soc_mapping_explanation")}
			</OptionBox>
		)
		let pEventsMapped = mappings.map(entry => entry.pEvent)

		let possibleCustomExpensesCodes = []
		if (currentExpenseTypeForCustomCode) {
			possibleCustomExpensesCodes =
				secSocCustomExpensesCodes.meta[currentExpenseTypeForCustomCode]
		}
		return (
			<ContentLayout customClass="settings-sec-soc-mapping" optionsBoxes={optionsBoxes}>
				<InputsChangeHandler customClass="mapping-creation" onChange={this.setState}>
					<div className="block events">
						<div className="inputs">
							<PootsySubHeading text={t("sec_soc_event_mappings_title")} />
							<PootsySelectInput
								name="currentPootsyEvent"
								defaultText={t("pootsy_events")}
								selected={currentPootsyEvent}
								options={pootsyEvents
									.filter(entry => !pEventsMapped.includes(entry))
									.map(entry => ({
										label: t("sec_soc_mapping_event_" + entry),
										value: entry,
									}))}
							/>
							<PootsySelectInput
								name="currentSecSocTimesheetCode"
								defaultText={t("sec_soc_terms")}
								selected={currentSecSocTimesheetCode}
								options={currAffiliate.secSocEvents.codes
									.filter(entry => entry.timesheet_category === "timesheet")
									.map(entry => ({
										label: entry.id + " " + t(entry.label),
										value: entry.id,
									}))}
							/>
							<PootsyButton
								text={t("add_mapping")}
								onClick={this.submitEventMapping}
							/>
						</div>
						<div className="saved">
							<PootsySubHeading text={t("sec_soc_mappings_saved")} />
							<div className="existing-mappings">
								{allFetched &&
									mappings.map(mapping => {
										let ssCode = currAffiliate.secSocEvents.codes.find(
											ssCode => ssCode.id === mapping.ssCode
										)
										if (ssCode === undefined) {
											return null
										}
										if (ssCode.timesheet_category !== "timesheet") {
											return null
										}
										return (
											<div
												key={mapping.id + mapping.pEvent}
												className="existing-mapping"
											>
												<div className="pootsy-term">
													{t(
														"sec_soc_mapping_event_" +
															pootsyEvents.find(
																pEvent => pEvent === mapping.pEvent
															)
													)}
												</div>
												<div className="sec-soc-term">
													{ssCode.id + " " + t(ssCode.label)}
												</div>
												<div className="remove">
													<img
														src={binIcon}
														alt="bin"
														onClick={this.removeSavedMapping}
														data-mappingid={mapping.id}
													/>
												</div>
											</div>
										)
									})}
							</div>
						</div>
					</div>
					<div className="block expenses">
						<div className="inputs">
							<PootsySubHeading text={t("sec_soc_expenses_mappings_title")} />
							<PootsySelectInput
								name="currentPootsyExpense"
								defaultText={t("pootsy_expenses")}
								selected={currentPootsyExpense}
								options={pootsyExpenses
									.filter(entry => !pEventsMapped.includes(entry))
									.map(entry => ({ label: t(entry), value: entry }))}
							/>
							<PootsySelectInput
								name="currentSecSocExpenseCode"
								defaultText={t("sec_soc_terms")}
								selected={currentSecSocExpenseCode}
								options={currAffiliate.secSocEvents.codes
									.filter(entry => entry.timesheet_category === "expense")
									.map(entry => ({
										label: entry.id + " " + t(entry.label),
										value: entry.id,
									}))}
							/>
							<PootsyButton
								text={t("add_mapping")}
								onClick={this.submitExpenseMapping}
							/>
						</div>
						<div className="saved">
							<PootsySubHeading text={t("sec_soc_mappings_saved")} />
							<div className="existing-mappings">
								{allFetched &&
									mappings.map(mapping => {
										let ssCode = currAffiliate.secSocEvents.codes.find(
											ssCode => ssCode.id === mapping.ssCode
										)
										if (ssCode === undefined) {
											return null
										}
										if (ssCode.timesheet_category !== "expense") {
											return null
										}
										return (
											<div
												key={mapping.id + mapping.pEvent}
												className="existing-mapping"
											>
												<div className="pootsy-term">
													{t(
														pootsyExpenses.find(
															pEvent => pEvent === mapping.pEvent
														)
													)}
												</div>
												<div className="sec-soc-term">
													{ssCode.id + " " + t(ssCode.label)}
												</div>
												<div className="remove">
													<img
														src={binIcon}
														alt="bin"
														onClick={this.removeSavedMapping}
														data-mappingid={mapping.id}
													/>
												</div>
											</div>
										)
									})}
							</div>
						</div>
					</div>
					{secSocCustomExpensesCodes.data && (
						<div className="block custom-expenses-codes">
							<div className="inputs">
								<PootsySubHeading
									text={t("sec_soc_custom_expenses_codes_mappings_title")}
								/>
								<PootsySelectInput
									name="currentExpenseTypeForCustomCode"
									defaultText={t("pootsy_expenses")}
									selected={currentExpenseTypeForCustomCode}
									options={secSocCustomExpensesCodes.meta.available_expenses_types.map(
										entry => ({ label: t(entry), value: entry })
									)}
								/>
								<PootsySelectInput
									name="currentCustomSecSocExpenseCode"
									defaultText={t("sec_soc_terms")}
									selected={currentCustomSecSocExpenseCode}
									options={possibleCustomExpensesCodes.map(entry => ({
										label: entry.id + " " + t(entry.label),
										value: entry.id,
									}))}
								/>
								<PootsyButton
									text={t("add_mapping")}
									onClick={this.handleSubmitCustomCodeClick}
								/>
							</div>
							<div className="saved">
								<PootsySubHeading text={t("sec_soc_mappings_saved")} />
								<div className="existing-mappings">
									{secSocCustomExpensesCodes.data.map(mapping => {
										let ssCode = currAffiliate.secSocEvents.codes.find(
											ssCode =>
												ssCode.id ===
												mapping.attributes.social_secretary_code
										)
										if (ssCode === undefined) {
											return null
										}
										return (
											<div
												key={mapping.id + mapping.pEvent}
												className="existing-mapping"
											>
												<div className="pootsy-term">
													{t(mapping.attributes.expense_type)}
												</div>
												<div className="sec-soc-term">
													{ssCode.id + " " + t(ssCode.label)}
												</div>
												<div className="remove">
													<img
														src={binIcon}
														alt="bin"
														onClick={this.removeSavedCustomExpenseCode}
														data-mappingid={mapping.id}
													/>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					)}
				</InputsChangeHandler>
			</ContentLayout>
		)
	}
}

SettingsSecSocMapping.contextTypes = { t: PropTypes.func }

const mapStateToProps = state => ({
	mappings: state.redData.secSocTermsMappings,
	pootsyEvents: state.redData.pootsyEvents,
	pootsyExpenses: state.redData.pootsyExpenses,
	secSocTerms: state.redData.secSocTerms,
	currAffiliate: state.redData.currentAffiliate,
	secSocCustomExpensesCodes: state.redData.secSocCustomExpensesCodes,
})
export default connect(mapStateToProps)(SettingsSecSocMapping)
