const countriesList = [
	{ label: "", value: "" },
	{ label: "albania", value: "101" },
	{ label: "andorra", value: "102" },
	{ label: "austria", value: "105" },
	{ label: "bulgaria", value: "106" },
	{ label: "cyprus", value: "107" },
	{ label: "denmark", value: "108" },
	{ label: "spain", value: "109" },
	{ label: "finland", value: "110" },
	{ label: "france", value: "111" },
	{ label: "united_kingdom", value: "112" },
	{ label: "luxembourg", value: "113" },
	{ label: "greece", value: "114" },
	{ label: "hungary", value: "115" },
	{ label: "ireland", value: "116" },
	{ label: "iceland", value: "117" },
	{ label: "liechtenstein", value: "118" },
	{ label: "malta", value: "119" },
	{ label: "monaco", value: "120" },
	{ label: "norway", value: "121" },
	{ label: "portugal", value: "123" },
	{ label: "romania", value: "124" },
	{ label: "san_marino", value: "125" },
	{ label: "sweden", value: "126" },
	{ label: "switzerland", value: "127" },
	{ label: "italy", value: "128" },
	{ label: "netherlands", value: "129" },
	{ label: "holy_see", value: "133" },
	{ label: "latvia", value: "135" },
	{ label: "estonia", value: "136" },
	{ label: "lithuania", value: "137" },
	{ label: "poland", value: "139" },
	{ label: "czechia", value: "140" },
	{ label: "slovakia", value: "141" },
	{ label: "belarus", value: "142" },
	{ label: "ukraine", value: "143" },
	{ label: "moldova_republic_of", value: "144" },
	{ label: "russian_federation", value: "145" },
	{ label: "croatia", value: "146" },
	{ label: "slovenia", value: "147" },
	{ label: "macedonia_the_former_yugoslav_republic_of", value: "148" },
	{ label: "bosnia_and_herzegovina", value: "149" },
	{ label: "belgium", value: "150" },
	{ label: "montenegro", value: "151" },
	{ label: "serbia", value: "152" },
	{ label: "kosovo", value: "153" },
	{ label: "germany", value: "173" },
	{ label: "gibraltar", value: "180" },
	{ label: "myanmar", value: "201" },
	{ label: "sri_lanka", value: "203" },
	{ label: "taiwan_province_of_china", value: "204" },
	{ label: "singapore", value: "205" },
	{ label: "korea_republic_of", value: "206" },
	{ label: "india", value: "207" },
	{ label: "indonesia", value: "208" },
	{ label: "japan", value: "209" },
	{ label: "lao_people_s_democratic_republic", value: "210" },
	{ label: "malaysia", value: "212" },
	{ label: "nepal", value: "213" },
	{ label: "philippines", value: "214" },
	{ label: "timor-leste", value: "215" },
	{ label: "cambodia", value: "216" },
	{ label: "china", value: "218" },
	{ label: "korea_democratic_people_s_republic_of", value: "219" },
	{ label: "viet_nam", value: "220" },
	{ label: "mongolia", value: "221" },
	{ label: "maldives", value: "222" },
	{ label: "bhutan", value: "223" },
	{ label: "brunei_darussalam", value: "224" },
	{ label: "kazakhstan", value: "225" },
	{ label: "kyrgyzstan", value: "226" },
	{ label: "uzbekistan", value: "227" },
	{ label: "tajikistan", value: "228" },
	{ label: "thailand", value: "235" },
	{ label: "bangladesh", value: "237" },
	{ label: "armenia", value: "249" },
	{ label: "azerbaijan", value: "250" },
	{ label: "afghanistan", value: "251" },
	{ label: "saudi_arabia", value: "252" },
	{ label: "georgia", value: "253" },
	{ label: "iraq", value: "254" },
	{ label: "iran", value: "255" },
	{ label: "israel", value: "256" },
	{ label: "jordan", value: "257" },
	{ label: "lebanon", value: "258" },
	{ label: "pakistan", value: "259" },
	{ label: "united_arab_emirates", value: "260" },
	{ label: "syrian_arab_republic", value: "261" },
	{ label: "turkey", value: "262" },
	{ label: "kuwait", value: "264" },
	{ label: "oman", value: "266" },
	{ label: "qatar", value: "267" },
	{ label: "bahrein", value: "268" },
	{ label: "yemen", value: "270" },
	{ label: "hong_kong", value: "280" },
	{ label: "macao", value: "281" },
	{ label: "timor", value: "282" },
	{ label: "palestine_state_of", value: "283" },
	{ label: "lesotho", value: "301" },
	{ label: "botswana", value: "302" },
	{ label: "burundi", value: "303" },
	{ label: "cameroon", value: "304" },
	{ label: "central_african_republic", value: "305" },
	{ label: "congo_the_democratic_republic_of_the", value: "306" },
	{ label: "congo", value: "307" },
	{ label: "burkinafaso", value: "308" },
	{ label: "ivory_coast", value: "309" },
	{ label: "benin", value: "310" },
	{ label: "ethiopia", value: "311" },
	{ label: "gabon", value: "312" },
	{ label: "gambia", value: "313" },
	{ label: "ghana", value: "314" },
	{ label: "guinea", value: "315" },
	{ label: "mauritius", value: "317" },
	{ label: "liberia", value: "318" },
	{ label: "mali", value: "319" },
	{ label: "senegal", value: "320" },
	{ label: "niger", value: "321" },
	{ label: "nigeria", value: "322" },
	{ label: "uganda", value: "323" },
	{ label: "madagascar", value: "324" },
	{ label: "sierra_leone", value: "328" },
	{ label: "somalia", value: "329" },
	{ label: "tanzania", value: "332" },
	{ label: "dhad", value: "333" },
	{ label: "togo", value: "334" },
	{ label: "zambia", value: "335" },
	{ label: "kenya", value: "336" },
	{ label: "equatorial_guinea", value: "337" },
	{ label: "guinea-bissau", value: "338" },
	{ label: "zimbabwe", value: "344" },
	{ label: "djibouti", value: "345" },
	{ label: "sao_tome_and_principe", value: "346" },
	{ label: "eritrea", value: "349" },
	{ label: "algeria", value: "351" },
	{ label: "egypt", value: "352" },
	{ label: "libyan", value: "353" },
	{ label: "morocco", value: "354" },
	{ label: "mauritania", value: "355" },
	{ label: "sudan", value: "356" },
	{ label: "tunisia", value: "357" },
	{ label: "malawi", value: "358" },
	{ label: "rwanda", value: "360" },
	{ label: "south_sudan", value: "365" },
	{ label: "angola", value: "381" },
	{ label: "mozambique", value: "383" },
	{ label: "namibia", value: "384" },
	{ label: "cabo_verde", value: "385" },
	{ label: "comoros", value: "386" },
	{ label: "reunion", value: "387" },
	{ label: "western_sahara", value: "388" },
	{ label: "seychelles(Eilanden)", value: "390" },
	{ label: "swaziland", value: "395" },
	{ label: "canada", value: "401" },
	{ label: "united_states_of_america", value: "402" },
	{ label: "costa_rica", value: "411" },
	{ label: "cuba", value: "412" },
	{ label: "guatemala", value: "413" },
	{ label: "honduras", value: "414" },
	{ label: "jamaica", value: "415" },
	{ label: "mexico", value: "416" },
	{ label: "nicaragua", value: "417" },
	{ label: "panama", value: "418" },
	{ label: "haiti", value: "419" },
	{ label: "dominican_republic", value: "420" },
	{ label: "el_salvador", value: "421" },
	{ label: "trinidad_and_tobago", value: "422" },
	{ label: "barbados", value: "423" },
	{ label: "grenada", value: "426" },
	{ label: "saint_lucia", value: "428" },
	{ label: "saint_vincent_and_the_grenadines", value: "429" },
	{ label: "belize", value: "430" },
	{ label: "saint_kitts_and_nevis", value: "431" },
	{ label: "virgin_islands_us", value: "478" },
	{ label: "virgin_islands_british", value: "479" },
	{ label: "dominica", value: "480" },
	{ label: "bahamas", value: "484" },
	{ label: "bermuda", value: "485" },
	{ label: "puerto_rico", value: "487" },
	{ label: "turks_and_caicos_islands", value: "488" },
	{ label: "antigua_and_barbuda", value: "491" },
	{ label: "cayman_islands", value: "492" },
	{ label: "montserrat", value: "493" },
	{ label: "saint_pierre_and_miquelon", value: "495" },
	{ label: "guadeloupe", value: "496" },
	{ label: "martinique", value: "497" },
	{ label: "greenland", value: "498" },
	{ label: "saint_barthelemy", value: "499" },
	{ label: "argentina", value: "511" },
	{ label: "bolivia", value: "512" },
	{ label: "brazil", value: "513" },
	{ label: "chile", value: "514" },
	{ label: "columbia", value: "515" },
	{ label: "ecuador", value: "516" },
	{ label: "paraguay", value: "517" },
	{ label: "peru", value: "518" },
	{ label: "uruguay", value: "519" },
	{ label: "venezuela", value: "520" },
	{ label: "guyana", value: "521" },
	{ label: "suriname", value: "522" },
	{ label: "falkland_islands_malvinas", value: "580" },
	{ label: "french_guiana", value: "581" },
	{ label: "micronesia_federated_states_of", value: "602" },
	{ label: "marshall_islands", value: "603" },
	{ label: "niue", value: "604" },
	{ label: "cook_islands", value: "605" },
	{ label: "australia", value: "611" },
	{ label: "new_zealand", value: "613" },
	{ label: "samoa", value: "614" },
	{ label: "nauru", value: "615" },
	{ label: "tonga", value: "616" },
	{ label: "fiji", value: "617" },
	{ label: "papua_new_guinea", value: "619" },
	{ label: "tuvalu", value: "621" },
	{ label: "kiribati", value: "622" },
	{ label: "solomon_islands", value: "623" },
	{ label: "vanuatu", value: "624" },
	{ label: "palau", value: "679" },
	{ label: "guam", value: "681" },
	{ label: "new_caledonia", value: "683" },
	{ label: "french_polynesia", value: "688" },
	{ label: "wallis_and_futuna(FR)", value: "689" },
	{ label: "american_samoa", value: "690" },
	{ label: "pitcairn", value: "692" },
	{ label: "refugee", value: "700" },
	{ label: "undefined", value: "711" },
	{ label: "unknown", value: "999" },
]
const nationalitiesList = [
	{ label: "albania", value: "101" },
	{ label: "andorra", value: "102" },
	{ label: "austria", value: "105" },
	{ label: "bulgaria", value: "106" },
	{ label: "cyprus", value: "107" },
	{ label: "denmark", value: "108" },
	{ label: "spain", value: "109" },
	{ label: "finland", value: "110" },
	{ label: "france", value: "111" },
	{ label: "united_kingdom", value: "112" },
	{ label: "luxembourg", value: "113" },
	{ label: "greece", value: "114" },
	{ label: "hungary", value: "115" },
	{ label: "ireland", value: "116" },
	{ label: "iceland", value: "117" },
	{ label: "liechtenstein", value: "118" },
	{ label: "malta", value: "119" },
	{ label: "monaco", value: "120" },
	{ label: "norway", value: "121" },
	{ label: "portugal", value: "123" },
	{ label: "romania", value: "124" },
	{ label: "san_marino", value: "125" },
	{ label: "sweden", value: "126" },
	{ label: "switzerland", value: "127" },
	{ label: "italy", value: "128" },
	{ label: "netherlands", value: "129" },
	{ label: "holy_see", value: "133" },
	{ label: "latvia", value: "135" },
	{ label: "estonia", value: "136" },
	{ label: "lithuania", value: "137" },
	{ label: "poland", value: "139" },
	{ label: "czechia", value: "140" },
	{ label: "slovakia", value: "141" },
	{ label: "belarus", value: "142" },
	{ label: "ukraine", value: "143" },
	{ label: "moldova_republic_of", value: "144" },
	{ label: "russian_federation", value: "145" },
	{ label: "croatia", value: "146" },
	{ label: "slovenia", value: "147" },
	{ label: "macedonia_the_former_yugoslav_republic_of", value: "148" },
	{ label: "bosnia_and_herzegovina", value: "149" },
	{ label: "belgium", value: "150" },
	{ label: "montenegro", value: "151" },
	{ label: "serbia", value: "152" },
	{ label: "kosovo", value: "153" },
	{ label: "germany", value: "173" },
	{ label: "gibraltar", value: "180" },
	{ label: "myanmar", value: "201" },
	{ label: "sri_lanka", value: "203" },
	{ label: "taiwan_province_of_china", value: "204" },
	{ label: "singapore", value: "205" },
	{ label: "korea_republic_of", value: "206" },
	{ label: "india", value: "207" },
	{ label: "indonesia", value: "208" },
	{ label: "japan", value: "209" },
	{ label: "lao_people_s_democratic_republic", value: "210" },
	{ label: "malaysia", value: "212" },
	{ label: "nepal", value: "213" },
	{ label: "philippines", value: "214" },
	{ label: "timor-leste", value: "215" },
	{ label: "cambodia", value: "216" },
	{ label: "china", value: "218" },
	{ label: "korea_democratic_people_s_republic_of", value: "219" },
	{ label: "viet_nam", value: "220" },
	{ label: "mongolia", value: "221" },
	{ label: "maldives", value: "222" },
	{ label: "bhutan", value: "223" },
	{ label: "brunei_darussalam", value: "224" },
	{ label: "kazakhstan", value: "225" },
	{ label: "kyrgyzstan", value: "226" },
	{ label: "uzbekistan", value: "227" },
	{ label: "tajikistan", value: "228" },
	{ label: "thailand", value: "235" },
	{ label: "bangladesh", value: "237" },
	{ label: "armenia", value: "249" },
	{ label: "azerbaijan", value: "250" },
	{ label: "afghanistan", value: "251" },
	{ label: "saudi_arabia", value: "252" },
	{ label: "georgia", value: "253" },
	{ label: "iraq", value: "254" },
	{ label: "iran", value: "255" },
	{ label: "israel", value: "256" },
	{ label: "jordan", value: "257" },
	{ label: "lebanon", value: "258" },
	{ label: "pakistan", value: "259" },
	{ label: "united_arab_emirates", value: "260" },
	{ label: "syrian_arab_republic", value: "261" },
	{ label: "turkey", value: "262" },
	{ label: "kuwait", value: "264" },
	{ label: "oman", value: "266" },
	{ label: "qatar", value: "267" },
	{ label: "bahrein", value: "268" },
	{ label: "yemen", value: "270" },
	{ label: "hong_kong", value: "280" },
	{ label: "macao", value: "281" },
	{ label: "timor", value: "282" },
	{ label: "palestine_state_of", value: "283" },
	{ label: "lesotho", value: "301" },
	{ label: "botswana", value: "302" },
	{ label: "burundi", value: "303" },
	{ label: "cameroon", value: "304" },
	{ label: "central_african_republic", value: "305" },
	{ label: "congo_the_democratic_republic_of_the", value: "306" },
	{ label: "congo", value: "307" },
	{ label: "burkinafaso", value: "308" },
	{ label: "ivory_coast", value: "309" },
	{ label: "benin", value: "310" },
	{ label: "ethiopia", value: "311" },
	{ label: "gabon", value: "312" },
	{ label: "gambia", value: "313" },
	{ label: "ghana", value: "314" },
	{ label: "guinea", value: "315" },
	{ label: "mauritius", value: "317" },
	{ label: "liberia", value: "318" },
	{ label: "mali", value: "319" },
	{ label: "senegal", value: "320" },
	{ label: "niger", value: "321" },
	{ label: "nigeria", value: "322" },
	{ label: "uganda", value: "323" },
	{ label: "madagascar", value: "324" },
	{ label: "sierra_leone", value: "328" },
	{ label: "somalia", value: "329" },
	{ label: "tanzania", value: "332" },
	{ label: "dhad", value: "333" },
	{ label: "togo", value: "334" },
	{ label: "zambia", value: "335" },
	{ label: "kenya", value: "336" },
	{ label: "equatorial_guinea", value: "337" },
	{ label: "guinea-bissau", value: "338" },
	{ label: "zimbabwe", value: "344" },
	{ label: "djibouti", value: "345" },
	{ label: "sao_tome_and_principe", value: "346" },
	{ label: "eritrea", value: "349" },
	{ label: "algeria", value: "351" },
	{ label: "egypt", value: "352" },
	{ label: "libyan", value: "353" },
	{ label: "morocco", value: "354" },
	{ label: "mauritania", value: "355" },
	{ label: "sudan", value: "356" },
	{ label: "tunisia", value: "357" },
	{ label: "malawi", value: "358" },
	{ label: "rwanda", value: "360" },
	{ label: "south_sudan", value: "365" },
	{ label: "angola", value: "381" },
	{ label: "mozambique", value: "383" },
	{ label: "namibia", value: "384" },
	{ label: "cabo_verde", value: "385" },
	{ label: "comoros", value: "386" },
	{ label: "reunion", value: "387" },
	{ label: "western_sahara", value: "388" },
	{ label: "seychelles(Eilanden)", value: "390" },
	{ label: "swaziland", value: "395" },
	{ label: "canada", value: "401" },
	{ label: "united_states_of_america", value: "402" },
	{ label: "costa_rica", value: "411" },
	{ label: "cuba", value: "412" },
	{ label: "guatemala", value: "413" },
	{ label: "honduras", value: "414" },
	{ label: "jamaica", value: "415" },
	{ label: "mexico", value: "416" },
	{ label: "nicaragua", value: "417" },
	{ label: "panama", value: "418" },
	{ label: "haiti", value: "419" },
	{ label: "dominican_republic", value: "420" },
	{ label: "el_salvador", value: "421" },
	{ label: "trinidad_and_tobago", value: "422" },
	{ label: "barbados", value: "423" },
	{ label: "grenada", value: "426" },
	{ label: "saint_lucia", value: "428" },
	{ label: "saint_vincent_and_the_grenadines", value: "429" },
	{ label: "belize", value: "430" },
	{ label: "saint_kitts_and_nevis", value: "431" },
	{ label: "virgin_islands_us", value: "478" },
	{ label: "virgin_islands_british", value: "479" },
	{ label: "dominica", value: "480" },
	{ label: "bahamas", value: "484" },
	{ label: "bermuda", value: "485" },
	{ label: "puerto_rico", value: "487" },
	{ label: "turks_and_caicos_islands", value: "488" },
	{ label: "antigua_and_barbuda", value: "491" },
	{ label: "cayman_islands", value: "492" },
	{ label: "montserrat", value: "493" },
	{ label: "saint_pierre_and_miquelon", value: "495" },
	{ label: "guadeloupe", value: "496" },
	{ label: "martinique", value: "497" },
	{ label: "greenland", value: "498" },
	{ label: "saint_barthelemy", value: "499" },
	{ label: "argentina", value: "511" },
	{ label: "bolivia", value: "512" },
	{ label: "brazil", value: "513" },
	{ label: "chile", value: "514" },
	{ label: "columbia", value: "515" },
	{ label: "ecuador", value: "516" },
	{ label: "paraguay", value: "517" },
	{ label: "peru", value: "518" },
	{ label: "uruguay", value: "519" },
	{ label: "venezuela", value: "520" },
	{ label: "guyana", value: "521" },
	{ label: "suriname", value: "522" },
	{ label: "falkland_islands_malvinas", value: "580" },
	{ label: "french_guiana", value: "581" },
	{ label: "micronesia_federated_states_of", value: "602" },
	{ label: "marshall_islands", value: "603" },
	{ label: "niue", value: "604" },
	{ label: "cook_islands", value: "605" },
	{ label: "australia", value: "611" },
	{ label: "new_zealand", value: "613" },
	{ label: "samoa", value: "614" },
	{ label: "nauru", value: "615" },
	{ label: "tonga", value: "616" },
	{ label: "fiji", value: "617" },
	{ label: "papua_new_guinea", value: "619" },
	{ label: "tuvalu", value: "621" },
	{ label: "kiribati", value: "622" },
	{ label: "solomon_islands", value: "623" },
	{ label: "vanuatu", value: "624" },
	{ label: "palau", value: "679" },
	{ label: "guam", value: "681" },
	{ label: "new_caledonia", value: "683" },
	{ label: "french_polynesia", value: "688" },
	{ label: "wallis_and_futuna(FR)", value: "689" },
	{ label: "american_samoa", value: "690" },
	{ label: "pitcairn", value: "692" },
	{ label: "refugee", value: "700" },
	{ label: "undefined", value: "711" },
	{ label: "unknown", value: "999" },
]

export default [
	{
		categoryName: "identification",
		inputs: [
			{
				label: "birth_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "place_of_birth_name",
				inputType: "text",
				required: false,
			},
			{
				label: "country_of_birth_name",
				inputType: "select",
				required: false,
				skipOptionsTranslationPrefix: true,
				options: countriesList,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.country_of_birth_name || currentWorker.country_of_birth_name,
			},
			{
				label: "nationality_code",
				inputType: "select",
				required: true,
				skipOptionsTranslationPrefix: true,
				options: nationalitiesList,
			},
			{
				label: "official_language_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "nl", value: "NL" },
					{ label: "fr", value: "FR" },
					{ label: "de", value: "DE" },
					{ label: "en", value: "EN" },
				],
				secSocPrefillable: true,
				skipTranslationPrefix: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.official_language_code || currentWorker.official_language_code,
			},
			{
				label: "fiscal_identification_number",
				inputType: "text",
				required: true,
			},
			{
				label: "marital_status_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "marital_status_single", value: "01" },
					{ label: "married", value: "02" },
					{ label: "marital_status_widow", value: "03" },
					{ label: "separated", value: "04" },
					{ label: "divorced", value: "05" },
					{ label: "legally_separated", value: "06" },
					{ label: "legally_cohabiting", value: "07" },
				],
				secSocPrefillable: false,
			},
		],
	},
	{
		categoryName: "contract",
		inputs: [
			{
				label: "iban",
				inputType: "text",
				required: true,
			},
			{
				label: "bic",
				inputType: "text",
				required: true,
			},
			{
				label: "funciton_code",
				inputType: "text",
				required: true,
			},
			{
				label: "workschedule_type_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "fixed", value: "1" },
					{ label: "variable", value: "2" },
					{ label: "none", value: "3" },
					{ label: "fixed_with_time_registration", value: "4" },
					{ label: "variable_with_time_registration", value: "5" },
				],
			},
			{
				label: "acerta_contract_number",
				inputType: "text",
				required: true,
			},
			{
				label: "statute_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "contractual", value: "01" },
					{ label: "temporary", value: "02" },
					{ label: "permanent_contract", value: "03" },
					{ label: "mandatary", value: "04" },
					{ label: "company_manager", value: "05" },
					{ label: "director", value: "06" },
					{ label: "manager_non_profit", value: "09" },
					{ label: "pensioner", value: "10" },
					{ label: "early_retiree", value: "11" },
					{ label: "widow", value: "12" },
					{ label: "orphan", value: "13" },
					{ label: "military_reserve", value: "14" },
					{ label: "volunteer", value: "16" },
					{ label: "unemployed", value: "17" },
					{ label: "pfi_stage", value: "18" },
					{ label: "time_credit_canada_dry", value: "19" },
					{ label: "unemployed_canada_dry", value: "20" },
					{ label: "professional_initiation", value: "21" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.statute_code || currentWorker.statute_code,
			},
			{
				label: "duration_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "indefinite_time", value: "01" },
					{ label: "definite_time", value: "02" },
					{ label: "well_defined_work", value: "03" },
					{ label: "replacement_contract", value: "04" },
					{ label: "limited_performances", value: "05" },
					{ label: "blue_white_collar_with_intervals", value: "06" },
					{ label: "seasonal_work_peak_extra_day_lump_sum", value: "07" },
					{ label: "definite_time_over_3_months_clerk_cat_1", value: "08" },
					{ label: "definite_time_over_3_months_clerk_cat_2", value: "09" },
					{ label: "well_defined_work_under_3_months", value: "10" },
					{ label: "well_defined_work_over_3_months", value: "11" },
					{ label: "replacement_contract_under_3_months", value: "12" },
					{ label: "replacement_contract_over_3_months", value: "13" },
					{ label: "seasonal_blue_collar_without_daily_lump", value: "14" },
					{ label: "definite_time_under_3_months", value: "15" },
					{ label: "flexi_with_dimona_period", value: "16" },
					{ label: "flexi_with_daily_dimona", value: "17" },
					{ label: "flexi_refused", value: "18" },
					{ label: "none", value: "99" },
				],
				secSocPrefillable: false,
			},
			{
				label: "employee_type_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "normal_category", value: "11" },
					{ label: "neo_trainee", value: "12" },
					{ label: "subsidised_contractor", value: "13" },
					{ label: "part_time_mandatory_school_age", value: "14" },
					{ label: "student_worker_mantadory_school_age", value: "15" },
					{ label: "first_work_experience", value: "16" },
					{ label: "interdepartmental_budget_fund", value: "17" },
					{ label: "third_labour_circuit", value: "18" },
					{ label: "hard_to_place_dis_jobseek_ersheltered_workshop", value: "19" },
					{ label: "physician_training_in_a_specialism", value: "20" },
					{ label: "grant_holders_without_bi_multilat", value: "21" },
					{ label: "prime", value: "22" },
					{ label: "on_trial", value: "23" },
					{ label: "contractual_replacement", value: "24" },
					{ label: "temporary_worker_horticulture", value: "50" },
					{ label: "taxi_driver", value: "51" },
					{ label: "academic_and_scientific_personel", value: "52" },
					{ label: "civil_servant_with_child_allowance", value: "53" },
					{ label: "holder_of_staff_position_in_gvt", value: "54" },
					{ label: "non_admin_and_tech_pers_univ", value: "55" },
					{ label: "ac_law_pers_cat_075", value: "56" },
					{ label: "physician", value: "70" },
					{ label: "exempted_physician", value: "71" },
					{ label: "monitor", value: "72" },
					{ label: "ministers_of_religion", value: "73" },
					{ label: "spectacle_artist", value: "74" },
					{ label: "volunteer_fireman", value: "75" },
					{ label: "exempted_volunteer", value: "76" },
					{ label: "unprotected_mandatary", value: "77" },
					{ label: "police_staff", value: "78" },
					{ label: "civilian_police_staff", value: "79" },
					{ label: "subsidised_personal_in_education", value: "80" },
					{ label: "syndical_seconded_employee", value: "81" },
					{
						label: "part_time_spectacle_artist_compulsory_school_age",
						value: "82",
					},
					{ label: "other_not_subject_to_nsso", value: "99" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.employee_type_code || currentWorker.employee_type_code,
			},
			{
				label: "employee_type_2_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "part_time_curriculum_or_work_experience", value: "01" },
					{ label: "home_work", value: "02" },
					{ label: "part_time_compulsory_school_age_or_apprentice", value: "03" },
					{ label: "commercial_representative", value: "04" },
					{ label: "retired_with_secondary_occupation", value: "05" },
					{ label: "student_not_subject_to_solidarity", value: "06" },
					{ label: "sect_cla_or_not_applicable", value: "09" },
					{ label: "artist", value: "10" },
					{ label: "performing_artist", value: "11" },
					{ label: "artist_student", value: "12" },
					{ label: "performing_artist_student", value: "13" },
					{ label: "artist_retired", value: "14" },
					{ label: "performing_artist_retired", value: "15" },
					{ label: "paid_athletes", value: "16" },
					{ label: "collective_agreement", value: "17" },
					{ label: "paid_athletes_retired", value: "18" },
					{ label: "individual_agreemant", value: "19" },
					{ label: "servant_blue_collar_worker", value: "20" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.employee_type_2_code || currentWorker.employee_type_2_code,
			},
			{
				label: "service_receiver_official_joint_committee_code",
				inputType: "select",
				required: true,
				options: [
					{
						label: "accr_co_providing_community_work_or_services",
						value: "322010",
					},
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.service_receiver_official_joint_committee_code ||
					currentWorker.service_receiver_official_joint_committee_code,
			},
			{
				label: "regularity_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "irregular_part_time", value: "1" },
					{ label: "regular_part_time", value: "2" },
					{ label: "irregular_full_time", value: "3" },
					{ label: "regular_full_time", value: "4" },
				],
				secSocPrefillable: false,
			},
			{
				label: "involuntary_parttime_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "part_time_employee_no_guaranteed_income_benefit", value: "1" },
					{ label: "part_time_employee_guaranteed_income_benefit", value: "2" },
					{ label: "part_time_employee_business_plan", value: "3" },
					{ label: "no_involuntary_part_time_employee", value: "4" },
				],
			},
			{
				label: "measure_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "full_career_break", value: "003" },
					{ label: "partial_career_break", value: "004" },
					{ label: "adjusted_work_with_loss_salary", value: "005" },
				],
			},
			{
				label: "external_contract_number",
				inputType: "text",
				required: true,
			},
			{
				label: "contract_custom_field_value",
				inputType: "select",
				required: true,
				options: [
					{ label: "worker", value: "0000000011" },
					{ label: "student", value: "0000000012" },
				],
			},
			{
				label: "hours_of_student_work_first_quarter_number",
				inputType: "text",
				required: false,
				show: values => values.contract_custom_field_value === "0000000012",
			},
			{
				label: "hours_of_student_work_second_quarter_number",
				inputType: "text",
				required: false,
				show: values => values.contract_custom_field_value === "0000000012",
			},
			{
				label: "hours_of_student_work_third_quarter_number",
				inputType: "text",
				required: false,
				show: values => values.contract_custom_field_value === "0000000012",
			},
			{
				label: "hours_of_student_work_fourth_quarter_number",
				inputType: "text",
				required: false,
				show: values => values.contract_custom_field_value === "0000000012",
			},
			{
				label: "hours_of_student_work_fifth_quarter_number",
				inputType: "text",
				required: false,
				show: values => values.contract_custom_field_value === "0000000012",
			},
		],
	},
	{
		categoryName: "partner",
		show: values => ["02", "04", "07"].includes(values["marital_status_code"]),
		inputs: [
			{
				label: "partner_first_name",
				inputType: "text",
				required: false,
			},
			{
				label: "partner_last_name",
				inputType: "text",
				required: false,
			},
			{
				label: "partner_disabled_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "yes", value: "1" },
					{ label: "no", value: "0" },
				],
			},
			{
				label: "partner_profession_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "single", value: "00" },
					{ label: "spouse_with_normal_prof", value: "01" },
					{ label: "spouse_with_pension", value: "02" },
					{ label: "spouse_eec", value: "03" },
					{ label: "spouse_with_professional_income", value: "08" },
					{ label: "spouse_without_professional_income", value: "09" },
				],
				secSocPrefillable: false,
			},
			{
				label: "marital_status_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "civil_marriage_date",
				inputType: "datepicker",
				required: false,
			},

			{
				label: "partner_birth_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "withholdingtaxes",
		inputs: [
			{
				label: "number_of_dependant_children",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_dependant_children ||
					currentWorker.number_of_dependant_children,
			},
			{
				label: "number_of_disabled_dependant_children",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_disabled_dependant_children ||
					currentWorker.number_of_disabled_dependant_children,
			},
			{
				label: "number_of_other_dependants",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_other_dependants ||
					currentWorker.number_of_other_dependants,
			},
			{
				label: "number_of_other_disabled_dependants",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_other_disabled_dependants ||
					currentWorker.number_of_other_disabled_dependants,
			},
			{
				label: "number_of_dependants_over_65",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_dependants_over_65 ||
					currentWorker.number_of_dependants_over_65,
			},
			{
				label: "number_of_disabled_dependants_over_65",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.number_of_disabled_dependants_over_65 ||
					currentWorker.number_of_disabled_dependants_over_65,
			},
			{
				label: "type_with_holding_tax_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "normal_subject_to_withholding_tax", value: "01" },
					{ label: "not_subject_to_witholding_tax", value: "02" },
					{ label: "witholding_tax_foreign_rate", value: "03" },
					{ label: "wt_on_incidental_or_periodic", value: "04" },
					{ label: "reserve_military_personnel", value: "05" },
					{ label: "temporary_and_seasonal_workers", value: "06" },
					{ label: "social_plan", value: "07" },
					{ label: "ordinary_subject_to_wt", value: "08" },
					{ label: "ngo_operator", value: "09" },
					{ label: "normal_wt_subject_with_min", value: "10" },
					{ label: "additional_sport_activities_normal_rate", value: "11" },
					{ label: "additional_sport_activities_rate_abroad", value: "12" },
					{ label: "performing_artist_non_resident", value: "13" },
					{ label: "casual_worker_catering_trade", value: "14" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.type_with_holding_tax_code ||
					currentWorker.type_with_holding_tax_code,
			},
			{
				label: "declaration_with_holding_tax_code",
				inputType: "select",
				required: true,
				filterOptions: values =>
					values["type_with_holding_tax_code"] === "04" ? ["1", "3", "5"] : [],
				options: [
					{ label: "form_no_declaration", value: "1" },
					{ label: "form_and_declaration", value: "2" },
					{ label: "no_form_no_declaration", value: "3" },
					{ label: "no_fiscal_documents", value: "5" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.declaration_with_holding_tax_code ||
					currentWorker.declaration_with_holding_tax_code,
			},
			{
				label: "service_receiver_cost_center_code_value",
				inputType: "select",
				required: false,
				options: [
					{ label: "ninove", value: "2227547689" },
					{ label: "kontich", value: "2227547986" },
					{ label: "kortenberg", value: "2256202083" },
					{ label: "herentals", value: "2256202182" },
					{ label: "berchem", value: "2256202281" },
					{ label: "kampenhout", value: "2256202380" },
					{ label: "grobbendonk", value: "2256202578" },
					{ label: "antwerpen", value: "2275709575" },
					{ label: "turnhout", value: "2275709674" },
					{ label: "stkatwaver", value: "2275709773" },
					{ label: "aalst", value: "2275709872" },
					{ label: "wijnegen", value: "2275709971" },
					{ label: "ekeren", value: "2275710070" },
					{ label: "asse", value: "2275710169" },
					{ label: "nijlen", value: "2275710268" },
					{ label: "wilrijk", value: "-" },
				],
			},
			{
				label: "disabled_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "yes", value: "1" },
					{ label: "no", value: "0" },
				],
			},
			{
				label: "leadership_level_code",
				inputType: "select",
				required: false,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.leadership_level_code || currentWorker.leadership_level_code,
				options: [
					{ label: "board_member", value: "01" },
					{ label: "white_collar", value: "02" },
					{ label: "blue_collar", value: "03" },
				],
			},
			{
				label: "education_degree_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "elementary_education", value: "01" },
					{ label: "secondary_education", value: "02" },
					{ label: "higher_non_univ_education", value: "03" },
					{ label: "high_university_education", value: "04" },
					{ label: "elementary_education_or_no_education", value: "11" },
					{ label: "lower_sec_ed_sp_ed_1_3", value: "12" },
					{ label: "second_year_3rd_grade_vocational_sec_ed", value: "21" },
					{ label: "third_gr_ts_ase_vse", value: "22" },
					{ label: "third_grade_sec_ed", value: "23" },
					{ label: "part_time_technical_ed", value: "24" },
					{ label: "part_time_vocational_sec_ed_higher_sec_ed", value: "25" },
					{ label: "sse_4_without_certificate_or_diploma", value: "26" },
					{ label: "seventh_y_vse_w_dip_se_cert", value: "27" },
					{ label: "higher_non_univ_education_short", value: "31" },
					{ label: "higher_non_univ_education_long", value: "32" },
					{ label: "university_education", value: "41" },
					{ label: "post_university_education", value: "42" },
					{ label: "phd_with_thesis", value: "43" },
				],
			},
			{
				label: "unmarried_with_dependant_child_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "yes", value: "1" },
					{ label: "no", value: "2" },
				],
				show: values =>
					values.marital_status_code === "01" ||
					values.marital_status_code === "03" ||
					values.marital_status_code === "05",
			},
		],
	},
	{
		categoryName: "dates",
		inputs: [
			{
				label: "in_organisation_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "external_employee_number",
				inputType: "text",
				required: false,
			},
			{
				label: "seniority_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "use_scales_code",
				inputType: "select",
				options: [
					{ label: "yes", value: "1" },
					{ label: "no", value: "0" },
				],
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.use_scales_code || currentWorker.use_scales_code,
			},
		],
	},
	{
		categoryName: "salary",
		inputs: [
			{
				label: "payment_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "non_cash", value: "1" },
					{ label: "instalments", value: "2" },
					{ label: "circular_cheque", value: "3" },
					{ label: "zero", value: "4" },
					{ label: "non_cash_non_automatic", value: "5" },
				],
				secSocPrefillable: false,
			},
			{
				label: "pay_frequency_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "pay_frequency_per_month", value: "01" },
					{ label: "weekly", value: "02" },
					{ label: "per_half_month", value: "06" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.pay_frequency_code || currentWorker.pay_frequency_code,
			},
			{
				label: "salary_calculation_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "service_cheques", value: "0" },
					{ label: "salary_calculation_per_month", value: "1" },
					{ label: "per_hour", value: "2" },
					{ label: "lump_sum", value: "3" },
					{ label: "per_piece", value: "4" },
					{ label: "with_service_money", value: "5" },
					{ label: "per_performance", value: "6" },
					{ label: "complete_with_commission_fee", value: "7" },
					{ label: "per_month_and_partial_with_commission_fee", value: "8" },
					{ label: "per_hour_and_partial_with_commission_fee", value: "9" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.salary_calculation_code || currentWorker.salary_calculation_code,
			},
			{
				label: "exploitation_point_sequence_number",
				inputType: "text",
				required: false,
			},
			{
				label: "gross_salary",
				inputType: "number",
				required: false,
				show: values => values.use_scales_code === "0",
			},
		],
	},
	{
		categoryName: "onss",
		inputs: [
			{
				label: "party_establishment_unit_number",
				inputType: "text",
				required: true,
			},
			{
				label: "sort_code",
				inputType: "select",
				required: true,
				options: [
					{ label: "blue_collar", value: "01" },
					{ label: "clerk", value: "02" },
					{ label: "domestic_worker", value: "03" },
					{ label: "self_employed", value: "04" },
					{ label: "individual_prof", value: "05" },
					{ label: "transition_internship", value: "06" },
					{ label: "not_applicable", value: "99" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.sort_code || currentWorker.sort_code,
			},
			{
				label: "service_receiver_prefix_noss_code",
				inputType: "select",
				required: true,
				options: [{ label: "service_voucher_companies", value: "597" }],
				secSocPrefillable: false,
			},
		],
	},
	// {
	// 	categoryName: "company_car",
	// 	show: values => ["company_car"].includes(values["vehicleType"]),
	// 	inputs: [
	// 		{
	// 			label: "amount_of_own_contribution_car",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "full_own_contribution_car_code",
	// 			inputType: "select",
	// 			required: false,
	// 			options: [
	// 				{ label: "full_own_contribution_company_car", value: "1" },
	// 				{ label: "non_full_own_contribution_company_car", value: "2" },
	// 			],
	// 			secSocPrefillable: false,
	// 		},
	// 		{
	// 			label: "commuting_code",
	// 			inputType: "select",
	// 			required: false,
	// 			options: [
	// 				{ label: "mixed_use", value: "1" },
	// 				{ label: "only_private", value: "2" },
	// 				{ label: "only_home_work", value: "3" },
	// 			],
	// 			secSocPrefillable: false,
	// 		},
	// 		{
	// 			label: "plate_number",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "brand_text",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "type_text",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "fuel_code",
	// 			inputType: "select",
	// 			required: false,
	// 			options: [
	// 				{ label: "petrol", value: "01" },
	// 				{ label: "diesel", value: "02" },
	// 				{ label: "electricity", value: "03" },
	// 			],
	// 		},
	// 		{
	// 			label: "number_of_co2",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "light_cargo_code",
	// 			inputType: "select",
	// 			required: false,
	// 			options: [{ label: "yes", value: "yes" }, { label: "no", value: "no" }],
	// 		},
	// 		{
	// 			label: "number_of_fiscal_hp",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "catalogue_value_amount",
	// 			inputType: "text",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "first_subscription_date",
	// 			inputType: "datepicker",
	// 			required: false,
	// 		},
	// 		{
	// 			label: "company_car_start_date",
	// 			inputType: "datepicker",
	// 			required: false,
	// 		},
	// 	],
	// },
	{
		categoryName: "settings",
		inputs: [
			{
				label: "service_receiver_steering_group_code",
				inputType: "text",
				required: true,
			},
			{
				label: "service_receiver_invoicing_group_number",
				inputType: "text",
				required: true,
			},
		],
	},
	{
		categoryName: "end_of_contract",
		show: values => values.endDate && values.endDate.isValid(),
		inputs: [
			{
				label: "end_reason",
				inputType: "select",
				required: false,
				options: [
					{ label: "mutual_agreement", value: "101" },
					{ label: "end_of_employment_contract_definite_time", value: "111" },
					{ label: "notice_by_employee", value: "121" },
					{ label: "notice_by_employer", value: "122" },
					{ label: "notice_by_employer_urgent", value: "131" },
					{ label: "termination_by_employer", value: "132" },
					{ label: "termination_by_employer_gross_misconduct", value: "135" },
					{ label: "termination_by_employer_protected_employee", value: "136" },
					{ label: "termination_by_employer_no_breakdown", value: "137" },
					{ label: "termination_by_employer_with_month_paid", value: "138" },
					{ label: "integration_allowance", value: "139" },
					{ label: "termination_by_employer_without_severance_pay", value: "140" },
					{ label: "early_retirement_swt", value: "141" },
					{ label: "no_longer_allowed", value: "142" },
					{ label: "termination_by_employer_early_retirement", value: "147" },
					{ label: "integration_compensation_early_retirement", value: "148" },
					{
						label: "termination_by_employer_without_severance_pay_bp",
						value: "149",
					},
					{ label: "pension", value: "151" },
					{ label: "early_retirement", value: "152" },
					{ label: "early_retirement_due_to_sickness", value: "153" },
					{ label: "death", value: "161" },
					{ label: "force_majeure_due_to_medical", value: "162" },
					{ label: "status_change", value: "171" },
					{ label: "from_trainee_to_permanent_contract", value: "172" },
					{ label: "change_to_other_legal_entity_within_bo", value: "181" },
					{ label: "change_to_other_legal_entity", value: "182" },
					{ label: "unemployed_no_bridging_pension", value: "191" },
					{
						label: "became_self_employed_less_than_half_time_at_the_company",
						value: "201",
					},
					{ label: "end_of_temporary_pension", value: "220" },
					{ label: "end_of_pts_pension", value: "221" },
					{ label: "cancellation_immediate_declaration", value: "230" },
					{ label: "other", value: "991" },
				],
				secSocPrefillable: false,
			},
			{
				label: "end_disruption_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "end_integration_compensation_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "notice_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
]
