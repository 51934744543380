import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { stringifyQuery } from "../helpers/qs"

import * as actions from "../actions"

import { NavLink } from "./NavLink"
import Filter from "./Filter"
import Paginator from "./Paginator"
import { PootsyTextInput, PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import {
	PageLayout,
	ContentLayout,
	OptionBox,
	PootsyButton,
	CustomerLine,
} from "./FunctionalDesign"
import NewCustomerModal from "./NewCustomerModal"
import CustomersPaymentReminders from "./CustomersPaymentReminders"
import ModalLayout from "./ModalLayout"

const customersPageQueries = {
	zipQuery: { urlName: "zipFilters", queryName: "filter_zip_code", mustBeSet: false },
	workersQuery: {
		urlName: "workersFilters",
		queryName: "filter_affiliate_workers_ids",
		mustBeSet: false,
	},
	commercialOptInQuery: {
		urlName: "commercialOptInFilters",
		queryName: "filter_commercial_opt_in",
		mustBeSet: false,
	},
	pageQuery: {
		urlName: "page",
		queryName: "page",
		mustBeSet: true,
		defaultValue: "1",
	},
	sortQuery: {
		urlName: "sort",
		queryName: "sort",
		mustBeSet: false,
	},
	searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
	accountLoggedInQuery: {
		urlName: "accountLoggedIn",
		queryName: "filter_account_logged_in",
		mustBeSet: true,
		defaultValue: "",
	},
	sortByRatingsQuery: {
		urlName: "sortByRatings",
		queryName: "sort_by_rating_average",
		mustBeSet: false,
	},
	activeQuery: {
		urlName: "activeFilter",
		queryName: "filter_active",
		mustBeSet: true,
		defaultValue: "active",
	},
	voucherQuery: {
		urlName: "voucherFilter",
		queryName: "filter_voucher",
		mustBeSet: false,
	},
	squadsQuery: {
		urlName: "squadsFilters",
		queryName: "squad_ids",
		mustBeSet: true,
	},
}
class Customers extends Component {
	state = {
		queuedCall: undefined,
		showNewCustomerModal: false,
		showExportModal: false,
		exportNote: "yes",
	}
	componentDidMount = async () => {
		let { dispatch } = this.props
		await this.fetchCustomerFilters()
		await this.initQueries()
		dispatch(actions.fetchCustomers())
	}
	componentDidUpdate = () => {
		const location = window.location
		const queryParams = new URLSearchParams(location.search)
		const page = queryParams.get("page")

		// If 'page' parameter exists, update the customersPageQueries with this value
		if (page) {
			customersPageQueries.pageQuery.defaultValue = page
		}

		let customerQueryNames = Object.keys(customersPageQueries)
		let currentQueryNames = Object.keys(this.props.currentQuery.queries)
		let wrongQuery =
			currentQueryNames.some(key => !customerQueryNames.includes(key)) ||
			customerQueryNames.some(key => !currentQueryNames.includes(key))
		if (this.props.location.pathname === "/customers" && wrongQuery) {
			this.initQueries()
		}
	}
	componentWillUnmount = () => {
		if (this.state.queuedCall) {
			clearTimeout(this.state.queuedCall)
		}
		this.props.dispatch(actions.resetQuery())
	}
	initQueries = () => {
		let { dispatch, currUser } = this.props
		customersPageQueries.squadsQuery.defaultValue = currUser.teams.map(entry => entry.id)
		dispatch(actions.replaceQueries(customersPageQueries))
	}
	fetchListDataExport = () => {
		let {
			dispatch,
			currentQuery: { realQuery },
		} = this.props
		realQuery["include_note"] = this.state.exportNote
		let url = "/front/affiliates/export_customer_list" + stringifyQuery(realQuery)
		let init = { method: "GET" }
		let requestMeta = { fileRequest: true, fileKeyName: "url" }
		return dispatch(actions.simpleFetch(url, init, "CUSTOMERS_LIST_EXPORT", requestMeta))
	}
	fetchListForPDF = () => {
		let {
			context: { t },
		} = this
		this.props.dispatch(actions.createCustomersListPDF(t))
	}
	fetchCustomerFilters = () => {
		let { dispatch } = this.props
		dispatch(
			actions.simpleFetch(
				"/front/affiliates/customer_filter",
				{ method: "GET" },
				"CUSTOMERSFILTERS"
			)
		)
	}
	toggleNewCustomerModal = () => {
		this.setState(state => ({ showNewCustomerModal: !state.showNewCustomerModal }))
	}
	toggleShowExportModal = () => {
		this.setState(state => ({ showExportModal: !state.showExportModal }))
	}
	updateQuery = async changes => {
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await this.props.dispatch(actions.updateQuery(changes))
		this.props.dispatch(actions.fetchCustomers())
	}
	handleSearchChange = e => {
		this.scheduleApiCall(e.target.value)
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		if (value.length !== 0 && value.length < 3) {
			return
		} else {
			futureCall = setTimeout(
				() => this.updateQuery({ name: "searchQuery", value: value }),
				800
			)
		}
		this.setState({ queuedCall: futureCall })
	}
	handleAccountLoggedInQueryChange = e => {
		this.updateQuery({ name: "accountLoggedInQuery", value: e.target.value })
	}
	changeExportNoteStatus = e => {
		this.setState({ exportNote: e.target.value })
	}
	handleSelectFilterChange = e => {
		let { name, value } = e.target
		this.updateQuery({ name, value })
	}
	closeModal = () => {
		this.setState({ showExportModal: false })
	}
	render() {
		let { t } = this.context
		let {
			match,
			currentQuery: {
				urlQuery: {
					zipFilters,
					workersFilters,
					squadsFilters,
					activeFilter,
					voucherFilter,
					commercialOptInFilters,
					page,
					accountLoggedIn,
					sortByRatings,
				},
			},
			customersComponent: { pagination, filters },
		} = this.props
		let pageHeaderLeft = <h1 className="page-title customers">{t("customers")}</h1>
		let pageHeaderRight = (
			<PootsyButton text={t("add_new_customer")} onClick={this.toggleNewCustomerModal} />
		)

		let boxOneFilters = []
		if (filters.areas) {
			boxOneFilters = [
				{
					queryId: "zipQuery",
					currValues: zipFilters,
					label: t("search_postal_codes"),
					filtersList: filters.areas.list,
				},
				{
					queryId: "workersQuery",
					currValues: workersFilters,
					label: t("search_cleaners"),
					filtersList: filters.workers.list,
				},
				{
					queryId: "squadsQuery",
					currValues: squadsFilters,
					label: t("search_squads"),
					filtersList: filters.squads.list,
				},
			].map(entry => {
				let possibleValues
				entry.filtersList === undefined
					? (possibleValues = [])
					: (possibleValues = entry.filtersList)
				return (
					<Filter
						key={entry.queryId}
						label={entry.label}
						name={entry.queryId}
						possibleValues={possibleValues}
						currentFilters={entry.currValues}
						onChange={this.updateQuery}
					/>
				)
			})
		}
		let accountLoggedInPossibleValues =
			(filters.account_logged_in && filters.account_logged_in.list) || []
		let boxOne = (
			<OptionBox key="show" boxTitle={t("show")}>
				{boxOneFilters}
				<PootsySelectInput
					name="commercialOptInQuery"
					defaultText={t("customer_commercial_opt_in_filter")}
					customClass="commercial-opt-in-select-filter"
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={commercialOptInFilters}
					options={(filters.commercial_opt_in && filters.commercial_opt_in.list) || []}
				/>
				<PootsySelectInput
					name="sortByRatingsQuery"
					defaultText={t("sort_by_ratings")}
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={sortByRatings}
					options={[
						{ label: t("asc_ordering"), value: "asc" },
						{ label: t("desc_ordering"), value: "desc" },
					]}
				/>
				<PootsySelectInput
					name="activeQuery"
					defaultText={t("active")}
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={activeFilter}
					options={[
						{ label: t("active"), value: "active" },
						{ label: t("inactive"), value: "inactive" },
						{ label: t("all"), value: "all" },
					]}
				/>
				<PootsySelectInput
					name="voucherQuery"
					defaultText={t("customer_voucher_type_preference")}
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={voucherFilter}
					options={[
						{ label: t("paper"), value: "paper" },
						{ label: t("electronic"), value: "electronic" },
					]}
				/>
				<PootsyRadioInputs
					groupName="accountLoggedInQuery"
					groupLabel={t("customers_with_account")}
					onChange={this.handleAccountLoggedInQueryChange}
					selected={accountLoggedIn}
					options={accountLoggedInPossibleValues.map(e => ({
						id: e.label,
						value: e.value,
						label: t(e.label),
					}))}
				/>
				<PootsyButton text={t("fetch_list_export")} onClick={this.toggleShowExportModal} />
				<PootsyButton text={t("download_list_pdf")} onClick={this.fetchListForPDF} />
			</OptionBox>
		)
		let optionsBoxes = [boxOne]
		let contentTop = (
			<PootsyTextInput
				label={t("search_customers")}
				name="search"
				customClass="customers-sort"
				onChange={this.handleSearchChange}
			/>
		)
		let links = [
			{ label: t("customers"), link: "/customers" },
			{ label: t("payment_reminders"), link: "/customers/payment_reminders" },
		]
		let subNavLinks = links.map(entry => (
			<NavLink key={entry.label} to={entry.link} exact={true}>
				{entry.label}
			</NavLink>
		))
		return (
			<Switch>
				<PageLayout
					headerLeft={pageHeaderLeft}
					headerRight={pageHeaderRight}
					subNavLinks={subNavLinks}
				>
					{this.state.showNewCustomerModal && (
						<NewCustomerModal
							fromPage="customers"
							onCancel={this.toggleNewCustomerModal}
						/>
					)}
					{this.state.showExportModal && (
						<ModalLayout closeModal={this.closeModal}>
							{t("includes_notes_to_export")}
							<PootsyRadioInputs
								selected={this.state.exportNote}
								onChange={this.changeExportNoteStatus}
								options={[
									{ id: 1, value: "yes", label: t("yes") },
									{ id: 2, value: "no", label: t("no") },
								]}
							/>
							<PootsyButton
								text={t("fetch_list_export")}
								onClick={this.fetchListDataExport}
							/>
						</ModalLayout>
					)}
					<Switch>
						<Route
							exact
							path={match.path + "/payment_reminders"}
							component={CustomersPaymentReminders}
						/>
						<Route
							exact
							path={match.path}
							render={() => (
								<ContentLayout
									customClass="customers"
									optionsBoxes={optionsBoxes}
									contentTop={contentTop}
								>
									<div className="cleaners-list">
										{this.props.customers.map(entry => (
											<CustomerLine key={entry.id} customer={entry} />
										))}
									</div>
									<Paginator
										name="pageQuery"
										currPage={page}
										totalPages={pagination.total_pages}
										goToPage={this.updateQuery}
									/>
								</ContentLayout>
							)}
						/>
					</Switch>
				</PageLayout>
			</Switch>
		)
	}
}

Customers.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	customers: state.redData.customers,
	customersComponent: state.redComponents.customersComponent,
	currUser: state.redData.currentUser,
	currentAffiliate: state.redData.currentAffiliate,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(Customers)
