import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import moment from "moment"
import { showWorkshop } from "../config"

import * as actions from "../actions"

import WorkerBookings from "./WorkerBookings"
import WorkerAbsences from "./WorkerAbsences"
import WorkerChat from "./WorkerChat"
import WorkerProfile from "./WorkerProfile"
import WorkerIncompatibilities from "./WorkerIncompatibilities"
import WorkerContract from "./WorkerContract"
import WorkerWeek from "./WorkerWeek"
import WorkerWorkshopOrders from "./WorkerWorkshopOrders"
import Fagenda from "./Fagenda"

import { PageLayout, NotificationCard, PootsyButton } from "./FunctionalDesign"
import { NavLink } from "./NavLink"
import ModalLayout from "./ModalLayout"
import { AbsenceModal } from "./AbsenceModal"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

// import previewIcon from '../static/images/preview-icon.png'
import bookingsIcon from "../static/images/bookings-icon.png"
import holidaysIcon from "../static/images/holidays-icon.png"
import profileIcon from "../static/images/profile-icon.png"
import contractIcon from "../static/images/contract-icon.png"
import chatIcon from "../static/images/chat-icon.png"
import arrowBack from "../static/images/arrowback-icon.png"
import WorkerTrainings from "./WorkerTrainings"

class WorkerPage extends Component {
	componentDidMount = () => {
		this.initWorkerPage()
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.submitAbsenceSuccess) {
			this.props.dispatch(actions.acknowledgeRequestSuccess(actions.CREATEABSENCE_SUCCESS))
			this.toggleNewAbsenceModal()
		}
		if (newProps.match.params.id !== this.props.match.params.id) {
			this.fetchWorker(newProps.match.params.id)
		}
	}
	initWorkerPage = () => {
		let {
			match: { params },
		} = this.props
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/languages",
				init,
				"FETCHAVAILABLEWORKERLANGUAGES"
			)
		)
		this.fetchWorker(params.id)
	}
	fetchWorker = id => {
		let { dispatch } = this.props
		dispatch(actions.fetchWorker(id))
	}
	prefillNewBooking = () => {
		let { sodexoNumber, displayName } = this.props.currentWorker
		let { dispatch, history } = this.props
		if (sodexoNumber !== undefined && displayName !== undefined) {
			history.push({ pathname: "/bookings/new" })
			dispatch(
				actions.prefillNewBookingPage({
					specificWorker: { name: displayName, sodexo: sodexoNumber },
				})
			)
		}
	}
	backInHistory = () => {
		this.props.history.goBack()
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workerPageStateChange(changes))
	}
	toggleNewAbsenceModal = () => {
		let { showNewAbsenceModal } = this.props.workerPage
		this.handleChanges({
			showNewAbsenceModal: !showNewAbsenceModal,
			areButtonsCollapsed: false,
			absenceStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
			absenceEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
			absenceType: "",
		})
	}
	toggleDataExportExplanationModal = () => {
		let { showDataExportExplanationModal } = this.props.workerPage
		this.handleChanges({
			showDataExportExplanationModal: !showDataExportExplanationModal,
		})
	}
	goToWorkerAgenda = () => {
		let { history, dispatch, currentWorker } = this.props
		dispatch(
			actions.agendaStateChange({
				customInitState: {
					workersFilters: [
						{
							affiliateWorkerId: currentWorker.id,
							value: currentWorker.id,
							label: currentWorker.displayName,
						},
					],
				},
			})
		)
		history.push({ pathname: "/" })
	}
	showWorkerAgendaLine = () => {
		let {
			dispatch,
			currentWorker,
			workerPage: { showAgendaLine },
		} = this.props
		if (showAgendaLine) {
			this.handleChanges({ showAgendaLine: false })
			return
		}
		dispatch(
			actions.agendaStateChange({
				customInitState: {
					workersFilters: [
						{
							affiliateWorkerId: currentWorker.id,
							value: currentWorker.id,
							label: currentWorker.displayName,
						},
					],
				},
			})
		)
		this.handleChanges({ showAgendaLine: true })
	}
	submitAbsence = () => {
		this.props.dispatch(actions.submitAbsenceFromWorkerPage())
	}
	resetWorkerData = () => {
		this.props.dispatch(actions.resetWorkerData())
	}
	submitAffiliateWorkerUpdate = () => {
		let { allWorkerLanguages, currentWorker } = this.props
		let spokenLanguages = []
		allWorkerLanguages.forEach(e => {
			let formKeys = Object.keys(currentWorker)
			if (formKeys.indexOf(e.ref) > -1 && currentWorker[e.ref]) {
				spokenLanguages.push(e.ref)
			}
		})
		let newCurrentWorker = {}
		Object.keys(currentWorker).forEach(attr => {
			if (currentWorker[attr] !== "" && currentWorker[attr] !== null) {
				newCurrentWorker[attr] = currentWorker[attr]
			} else {
				newCurrentWorker[attr] = null
			}
		})
		let {
			id,
			sodexoNumber,
			nissNumber,
			title,
			firstName,
			lastName,
			email,
			mobilePhone,
			street,
			streetNumber,
			box,
			city,
			zip,
			country,
			sodexoPin,
			mainLanguage,
			affiliateWorkerNote,
			secSocIdentifier,
			sixtyPercentRuleEligible,
			region,
			identityCardNumber,
			allergies,
			excludedFromTSS,
			canWorkWithAnimals,
			comment,
			squadIds,
			needsUpfrontPayment,
			externalPartnerId,
		} = newCurrentWorker
		let data = {
			affiliate_worker: {
				sodexo_reference: sodexoNumber,
				niss: nissNumber,
				title: title,
				first_name: firstName,
				last_name: lastName,
				email: email,
				telephone: mobilePhone,
				social_secretary_identifier: secSocIdentifier,
				default_language: mainLanguage,
				spoken_languages: spokenLanguages,
				affiliate_note: affiliateWorkerNote,
				sodexo_pin: sodexoPin,
				region: region,
				identity_card_number: identityCardNumber,
				excluded_from_tss: excludedFromTSS,
				can_work_with_animals: canWorkWithAnimals,
				comment: comment,
				sixty_percent_rule_eligible: sixtyPercentRuleEligible,
				squad_ids: squadIds,
				needs_upfront_payment: needsUpfrontPayment,
				allergies,
				external_partner_id: externalPartnerId,
				address: {
					street_name: street,
					street_number: streetNumber,
					street_box: box,
					zip_code: zip,
					town: city,
					country: country,
				},
			},
		}
		let init = { method: "PUT", body: JSON.stringify(data) }
		this.props.dispatch(
			actions.simpleFetch("/front/affiliates/affiliate_workers/" + id, init, "UPDATEWORKER")
		)
	}
	getWorkerDataExport = () => {
		let {
			dispatch,
			match: { params },
		} = this.props
		let url = "/front/affiliates/affiliate_workers/" + params.id + "/data_exports"
		let init = { method: "POST" }
		dispatch(actions.simpleFetch(url, init, "GET_WORKER_DATA_EXPORT"))
		this.toggleDataExportExplanationModal()
	}
	render() {
		let { t } = this.context
		let {
			match,
			currentWorker,
			workerContracts,
			currAffiliate,
			currUser,
			impactedBookingsModal: { showImpactedBookingsModal },
			workerPage: {
				showNewAbsenceModal,
				showDataExportExplanationModal,
				activityCode,
				activityCategory,
				absenceStart,
				absenceEnd,
				absenceNote,
				formC32ANumber,
				mustCallOnss,
				showAgendaLine,
			},
		} = this.props
		let { displayName, editedKeys } = currentWorker
		let pageHeaderLeft = (
			<div className="cleaner-details-page-header-left">
				<img
					alt="arrow back"
					className="arrow-back"
					src={arrowBack}
					onClick={this.backInHistory}
				/>
				<h1 className="page-title">{displayName}</h1>
			</div>
		)
		let pageHeaderRight = (
			<div className="cleaner-details-header-buttons">
				<PootsyButton
					text={t("export_worker_data_button")}
					onClick={this.getWorkerDataExport}
				/>
				<PootsyButton
					text={t("add_new_absence_for") + " " + displayName}
					onClick={this.toggleNewAbsenceModal}
				/>
				<PootsyButton
					text={t("add_new_booking_for") + " " + displayName}
					onClick={this.prefillNewBooking}
				/>
				<PootsyButton text={t("go_to_worker_agenda")} onClick={this.goToWorkerAgenda} />
			</div>
		)
		let links = [
			// { label: t('preview'), link: 'preview', icon: previewIcon },
			{ order: 1, label: t("bookings"), link: "bookings", icon: bookingsIcon },
			{ order: 2, label: t("absences"), link: "absences", icon: holidaysIcon },
			// { label: t('templates'), link: 'templates', icon: contractIcon },
			// { label: t('contract_and_availabilities'), link: 'availabilities', icon: contractIcon },
			// { label: t('regular_schedules'), link: 'recurring', icon: contractIcon },
			{ order: 3, label: t("profile"), link: "profile", icon: profileIcon },
			{ order: 4, label: t("contract"), link: "contract", icon: contractIcon },
			// { label: t('incompatibilities'), link: 'incompatibilities', icon: contractIcon },
			{ order: 6, label: t("chat"), link: "chat", icon: chatIcon },
			{ order: 7, label: t("trainings"), link: "trainings", icon: bookingsIcon },
		]
		if (showWorkshop && currAffiliate.workshopAddOn) {
			links.push({
				order: 7,
				label: t("workshop_orders"),
				link: "workshop-orders",
				icon: chatIcon,
			})
		}
		if (workerContracts.length > 0) {
			links.push({ order: 5, label: t("real_planning"), link: "week", icon: contractIcon })
		}
		if (!currUser.isMaster && currUser.restrictions.length > 0) {
			links = links.filter(link => {
				return !currUser.restrictions.some(restr => {
					return link.label === t(restr)
				})
			})
		}
		let subNavLinks = links
			.sort((a, b) => a.order - b.order)
			.map(entry => (
				<NavLink
					key={entry.link}
					className="cleaner-details-subnav-link"
					to={match.url + "/" + entry.link}
				>
					<img alt="icon" className="cleaner-details-subnav-icon" src={entry.icon} />
					{entry.label}
				</NavLink>
			))
		let notifButtons = [
			<PootsyButton
				key="a"
				text={t("save_changes")}
				onClick={this.submitAffiliateWorkerUpdate}
			/>,
			<PootsyButton
				key="b"
				text={t("reset_changes")}
				theme="cancel"
				onClick={this.resetWorkerData}
			/>,
		]
		subNavLinks.push(
			<NotificationCard
				key="notif"
				text={t("notif_unsaved_changes")}
				active={editedKeys.length > 0}
				buttons={notifButtons}
			/>
		)
		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				headerRight={pageHeaderRight}
				subNavLinks={subNavLinks}
			>
				{showAgendaLine && <Fagenda detachedMode={true} />}
				{currentWorker.scheduledJobs && currentWorker.scheduledJobs.length > 0 && (
					<div className="worker-scheduled-jobs">
						{currentWorker.scheduledJobs.map(entry => (
							<div key={entry.id} className="scheduled-job">
								{t("worker_scheduled_job." + entry.job_type.toLowerCase(), {
									scheduledDate: moment(entry.scheduled_date).format(
										"DD/MM/YYYY"
									),
								})}
							</div>
						))}
					</div>
				)}
				{showNewAbsenceModal && (
					<AbsenceModal
						activityCode={activityCode}
						activityCategory={activityCategory}
						absenceStart={absenceStart}
						absenceEnd={absenceEnd}
						absenceStatus="approved"
						absenceNote={absenceNote}
						formC32ANumber={formC32ANumber}
						mustCallOnss={mustCallOnss}
						lastEuAbsenceDate={currentWorker.lastEuAbsenceDate}
						toggleModal={this.toggleNewAbsenceModal}
						onChange={this.handleChanges}
						submit={this.submitAbsence}
						t={t}
					/>
				)}
				{showDataExportExplanationModal && (
					<ModalLayout
						closeModal={this.toggleDataExportExplanationModal}
						formDivClass="data-export-explanation-modal"
						buttons={[
							<PootsyButton
								key="a"
								text={t("dismiss")}
								onClick={this.toggleDataExportExplanationModal}
							/>,
						]}
					>
						{t("data_export_explanation_modal")}
					</ModalLayout>
				)}
				{showImpactedBookingsModal && (
					<ImpactedBookingsModal
						onSubmit={this.submitAbsence}
						triggerActionExpected={actions.CREATEABSENCE_FAILURE}
					/>
				)}
				<Route path={match.path + "/bookings"} component={WorkerBookings} />
				<Route path={match.path + "/absences"} component={WorkerAbsences} />
				<Route path={match.path + "/profile"} component={WorkerProfile} />
				<Route path={match.path + "/contract"} component={WorkerContract} />
				<Route path={match.path + "/week"} component={WorkerWeek} />
				<Route
					path={match.path + "/incompatibilities"}
					component={WorkerIncompatibilities}
				/>
				<Route path={match.path + "/chat"} component={WorkerChat} />
				{showWorkshop && (
					<Route
						path={match.path + "/workshop-orders"}
						component={WorkerWorkshopOrders}
					/>
				)}
				<Route path={match.path + "/trainings"} component={WorkerTrainings} />
			</PageLayout>
		)
	}
}

WorkerPage.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	allWorkerLanguages: state.redData.allWorkerLanguages,
	submitAbsenceSuccess: state.requestsSuccess[actions.CREATEABSENCE_SUCCESS],
	workerContracts: state.redData.workerContracts,
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
	workerPage: state.redComponents.workerPage,
})
export default connect(mapStateToProps)(WorkerPage)
