import React, { Fragment, useContext } from "react"
import moment from "moment"

// import * as actions from "../actions"
import { LocaleContext } from "../index.js"

import AgendaTimerange from "./AgendaTimerange"
import { WeekBadge } from "./FunctionalDesign"
import {
	useWorkerDropdownToggling,
	useTimerangeDropdownClosing,
	useWorkerPrefilledBooking,
	useDisplayWorkerFullMonth,
	useGoToWorkerPage,
} from "./AgendaHooks"
import { Lock } from "../svg/lock"

const WorkerInfoCell = ({
	schedule: { cleaner, weekStart },
	activeWorkerDrop,
	fullMonthAffiliateWorkerId,
	fetchAgenda,
	currentQuery,
}) => {
	const t = useContext(LocaleContext)
	const closeRangeDropdown = useTimerangeDropdownClosing()
	const handlePrefillBooking = useWorkerPrefilledBooking()
	const toggleWorkerDropdown = useWorkerDropdownToggling(
		activeWorkerDrop,
		cleaner,
		closeRangeDropdown
	)
	const handleDisplayWorkerFullMonth = useDisplayWorkerFullMonth(
		toggleWorkerDropdown,
		fetchAgenda
	)
	const goToWorkerPage = useGoToWorkerPage()
	if (fullMonthAffiliateWorkerId) {
		let monthStart = moment(currentQuery.urlQuery.week).startOf("month")
		let isFirstRow = weekStart.isSame(monthStart, "isoweek")
		if (!isFirstRow) {
			return <div className="WorkerInfoCell" />
		}
	}
	const cleaner_full_name = cleaner.first_name
		? `${cleaner.last_name} ${cleaner.first_name}`
		: cleaner.name
	const cleaner_initials =
		cleaner.first_name && cleaner.last_name
			? cleaner?.last_name[0] + cleaner?.first_name[0]
			: cleaner.initials
	const cleaner_name_with_ssid =
		(cleaner.socSecId ? `${cleaner.socSecId} / ` : "") + cleaner_full_name

	console.error("Cleaner", cleaner)
	return (
		<div className="WorkerInfoCell">
			<div
				className="clickable-cleaner-cell"
				data-cleanerid={cleaner.id}
				onClick={toggleWorkerDropdown}
			/>
			<span className="initials-circle">{cleaner_initials}</span>
			<div className="name-areas">
				<div className="name">{cleaner_name_with_ssid}</div>
			</div>
			<div
				className={
					"agenda-cleaner-dropdown " + (activeWorkerDrop === cleaner.id ? "active" : "")
				}
			>
				<div className="close-button" onClick={toggleWorkerDropdown}>
					X
				</div>
				<div
					className="cleaner-dropdown-button"
					onClick={handlePrefillBooking}
					data-worker-id={cleaner.id}
				>
					{t("add_new_booking_for") + " " + cleaner.name}
				</div>
				<div
					className="cleaner-dropdown-button"
					onClick={handleDisplayWorkerFullMonth}
					data-worker-id={cleaner.affiliateWorkerId}
				>
					{t("see_full_month")}
				</div>
				<div
					className="cleaner-dropdown-button"
					onClick={goToWorkerPage}
					data-worker-id={cleaner.affiliateWorkerId}
				>
					{t("see_cleaner_details")}
				</div>
			</div>
		</div>
	)
}

const FagendaRow = ({
	schedule,
	fullMonthAffiliateWorkerId,
	activeTooltipRange,
	secSocEvents,
	minutesWidth,
	activeWorkerDrop,
	workshops,
	fetchAgenda,
	currentQuery,
	currUser,
}) => {
	const t = useContext(LocaleContext)
	let { cleaner, timeRanges, weekData, weekStart } = schedule
	let days = []
	for (let m = moment(weekStart); m.isSame(weekStart, "isoweek"); m.add(1, "day")) {
		days.push(moment(m))
	}
	const badge = weekData.display_data.find(entry => entry.translation_key === "badge")
	let canChangeContract =
		currUser.isMaster ||
		!currUser.restrictions.some(rest => {
			return rest === "contract"
		})
	return (
		<div className="AgendaRow">
			{fullMonthAffiliateWorkerId && (
				<div className="day-labels">
					{days.map(day => (
						<div key={day.format("DD/MM")} className="day-label">
							{day.day() !== 0 && day.format("DD/MM")}
						</div>
					))}
				</div>
			)}
			<div className="row-wrapper">
				<WorkerInfoCell
					schedule={schedule}
					activeWorkerDrop={activeWorkerDrop}
					fullMonthAffiliateWorkerId={fullMonthAffiliateWorkerId}
					fetchAgenda={fetchAgenda}
					currentQuery={currentQuery}
				/>
				<div className="agenda-day-cells">
					<div className="padder">
						{days.map(day => {
							if (day.isoWeekday() !== 7) {
								return (
									<div key={day.format()} className="agenda-day-cell">
										<div className="day-separator" />
									</div>
								)
							} else {
								return (
									<div key={day.format()} className="agenda-worker-week-data">
										<div className="day-separator" />
										<div className="infos">
											{weekData.display_data ? (
												weekData.display_data.map(entry => {
													if (entry.translation_key === "badge") {
														return null
													}
													let label = t(
														entry.translation_key,
														entry.translation_variables
													)
													return (
														<div
															key={entry.translation_key}
															className="info"
														>
															<div className="label">{label}</div>
															<div className="value">
																{entry.value}
															</div>
														</div>
													)
												})
											) : (
												<Fragment>
													<div className="info">
														<div className="label">
															{t("contract_hours")}
														</div>
														<div className="value">
															{weekData.contractHours}
														</div>
													</div>
													<div className="info">
														<div className="label">
															{t("worked_hours")}
														</div>
														<div className="value">
															{weekData.workedHours}
														</div>
													</div>
													<div className="info">
														<div className="label">
															{t("vacant_hours")}
														</div>
														<div className="value">
															{weekData.vacantHours}
														</div>
													</div>
													<WeekBadge badge={weekData.badge} />
												</Fragment>
											)}
											<div className="lock-badge">
												{weekData.closed_week && (
													<div
														className={
															"week-lock " +
															(weekData.closed_week.closed
																? "lock-red"
																: "lock-green")
														}
														// onClick={this.submitLockAgendaWeek}
													>
														<Lock
															closed={weekData.closed_week.closed}
														/>
													</div>
												)}
												{badge && (
													<WeekBadge key="badge" badge={badge.value} />
												)}
											</div>
										</div>
									</div>
								)
							}
						})}
						{timeRanges
							.filter(tr => tr.start.day() !== 0)
							.map((tr, i) => (
								<AgendaTimerange
									key={i}
									tr={tr}
									trIndex={i}
									cleaner={cleaner}
									secSocEvents={secSocEvents}
									weekStart={weekStart}
									minutesWidth={minutesWidth}
									workshops={workshops}
									showTrDropdown={
										canChangeContract &&
										activeTooltipRange.ci === cleaner.id &&
										activeTooltipRange.ti === i &&
										activeTooltipRange.tid === tr.id
									}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

export const AgendaRowPlaceholder = () => {
	return (
		<div className="AgendaRowPlaceholder">
			<div className="row-wrapper">
				<div className="WorkerInfoCell">
					<div className="clickable-cleaner-cell" />
					<span className="initials-circle">{"  "}</span>
					<div className="name-areas">
						<div className="name">
							<div className="name-placeholder" />
						</div>
					</div>
				</div>
				<div className="agenda-day-cells">
					<div className="padder">
						{[1, 2, 3, 4, 5, 6, 7].map(day => {
							if (day !== 7) {
								return (
									<div key={day} className="agenda-day-cell">
										<div className="day-separator" />
									</div>
								)
							} else {
								return (
									<div key={day} className="agenda-worker-week-data">
										<div className="day-separator" />
										<div className="infos"></div>
									</div>
								)
							}
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FagendaRow
