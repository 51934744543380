import React, { Component } from "react"
import { stringifyQuery } from "../helpers/qs"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"

import * as actions from "../actions"

import Filter from "./Filter"
import { PootsyTextInput, PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import Paginator from "./Paginator"
import { PageLayout, ContentLayout, OptionBox, PootsyButton, WorkerLine } from "./FunctionalDesign"
import { NavLink } from "./NavLink"

class Workers extends Component {
	componentDidMount = async () => {
		let { dispatch, currUser } = this.props
		await dispatch(
			actions.simpleFetch(
				"/front/affiliates/worker_filter",
				{ method: "GET" },
				"WORKERSFILTERS"
			)
		)
		await dispatch(
			actions.addQueries({
				squadsQuery: {
					urlName: "squadsFilters",
					queryName: "filter_squads",
					mustBeSet: true,
					defaultValue: currUser.teams.map(entry => entry.id),
				},
				pageQuery: {
					urlName: "page",
					queryName: "page",
					mustBeSet: true,
					defaultValue: "1",
				},
				accountLoggedInQuery: {
					urlName: "accountLoggedIn",
					queryName: "filter_account_logged_in",
					mustBeSet: true,
					defaultValue: "",
				},
				statusQuery: {
					urlName: "status",
					queryName: "filter_status",
					mustBeSet: true,
					defaultValue: "active",
				},
				zipQuery: {
					urlName: "zipFilters",
					queryName: "filter_zip_codes",
					mustBeSet: false,
				},
				socSecIdQuery: {
					urlName: "socSecIdFilters",
					queryName: "filter_soc_sec_id",
					mustBeSet: true,
				},
				contractStartDateQuery: {
					urlName: "contractStartDateFilters",
					queryName: "filter_contract_start_date",
					mustBeSet: true,
				},
				searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
				sortByRatingsQuery: {
					urlName: "sortByRatings",
					queryName: "sort_by_rating_average",
					mustBeSet: false,
				},
			})
		)
		dispatch(actions.fetchWorkers())
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetQuery())
		this.debouncedUpdateQuery.cancel()
	}
	fetchListForPDF = () => {
		let {
			props: {
				currentQuery: { realQuery },
			},
			context: { t },
		} = this
		this.props.dispatch(actions.createWorkersListPDF(t, stringifyQuery(realQuery)))
	}
	UNSAFE_componentWillReceiveProps = nextProps => {
		let { location } = nextProps
		if (location.search.includes("cleanerLimitReached")) {
			this.setState({ showWorkerLimitReachedModal: true })
		}
	}
	fetchListDataExport = () => {
		let {
			dispatch,
			currentQuery: { realQuery },
		} = this.props
		let url = "/front/affiliates/export_worker_list?" + stringifyQuery(realQuery)
		let init = { method: "GET" }
		let requestMeta = { fileRequest: true, fileKeyName: "url" }
		dispatch(actions.simpleFetch(url, init, "WORKERS_LIST_EXPORT", requestMeta))
	}
	updateQuery = async changes => {
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await this.props.dispatch(actions.updateQuery(changes))
		this.props.dispatch(actions.fetchWorkers())
	}
	handleSearchChange = e => {
		let { value } = e.target
		if (value.length === 0 || value.length > 2) {
			this.debouncedUpdateQuery({ name: "searchQuery", value: value })
		}
	}
	debouncedUpdateQuery = _.debounce(changes => {
		this.updateQuery(changes)
	}, 1500)
	dismissWorkerLimitReachedModal = () => {
		let { dispatch } = this.props
		dispatch(actions.workersStateChange({ showWorkerLimitReachedModal: false }))
	}
	handleAccountLoggedInQueryChange = e => {
		this.updateQuery({ name: "accountLoggedInQuery", value: e.target.value })
	}
	handleSelectFilterChange = e => {
		let { name, value } = e.target
		this.updateQuery({ name, value })
	}
	handleSocSecIdFilterChange = e => {
		let { value } = e.target
		if (value.length === 0 || value.length >= 1) {
			this.debouncedUpdateQuery({ name: "socSecIdQuery", value: value })
		}
	}

	handleContractStartDateFilterChange = e => {
		let { value } = e.target
		if (value.length === 0 || value.length >= 1) {
			this.debouncedUpdateQuery({ name: "contractStartDateQuery", value: value })
		}
	}
	render() {
		let { t } = this.context
		let {
			workersComponent: { pagination, filters, showWorkerLimitReachedModal },
			currentQuery: {
				urlQuery: {
					page,
					zipFilters,
					squadsFilters,
					status,
					accountLoggedIn,
					sortByRatings,
				},
			},
		} = this.props
		if (filters.areas === undefined) {
			return null
		}
		let boxOneFilters = [
			{
				queryId: "zipQuery",
				currValues: zipFilters,
				label: t("postal_codes"),
				filtersList: filters.areas.list,
			},
			{
				queryId: "squadsQuery",
				currValues: squadsFilters,
				label: t("search_squads"),
				filtersList: filters.squads.list,
			},
		].map(entry => {
			let possibleValues
			entry.filtersList === undefined
				? (possibleValues = [])
				: (possibleValues = entry.filtersList)
			return (
				<Filter
					key={entry.queryId}
					label={entry.label}
					name={entry.queryId}
					possibleValues={possibleValues}
					currentFilters={entry.currValues}
					onChange={this.updateQuery}
				/>
			)
		})
		let accountLoggedInPossibleValues = filters.account_logged_in.list || []
		let boxOne = (
			<OptionBox key="show" boxTitle={t("show")}>
				{boxOneFilters}
				<PootsySelectInput
					name="statusQuery"
					defaultText={t("workers_statuses")}
					customClass="bookings-select-filter"
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={status}
					options={[
						{ label: t("active"), value: "active" },
						{ label: t("inactive"), value: "inactive" },
						{ label: t("all"), value: "all" },
					]}
				/>
				<PootsyTextInput
					label={t("contract_start_date")}
					name="contractStartDateFilters"
					customClass="bookings-text-filter"
					type="date"
					onChange={this.handleContractStartDateFilterChange}
				/>
				<PootsySelectInput
					name="sortByRatingsQuery"
					defaultText={t("sort_by_ratings")}
					customClass="bookings-select-filter"
					allowEmpty={true}
					onChange={this.handleSelectFilterChange}
					selected={sortByRatings}
					options={[
						{ label: t("asc_ordering"), value: "asc" },
						{ label: t("desc_ordering"), value: "desc" },
					]}
				/>
				<PootsyTextInput
					label={t("soc_sec_id_filters")}
					name="socSecIdFilters"
					customClass="bookings-text-filter"
					onChange={this.handleSocSecIdFilterChange}
				/>
				<PootsyRadioInputs
					groupName="accountLoggedInQuery"
					groupLabel={t("cleaners_with_account")}
					onChange={this.handleAccountLoggedInQueryChange}
					selected={accountLoggedIn}
					options={accountLoggedInPossibleValues.map(e => ({
						id: e.label,
						value: e.value,
						label: t(e.label),
					}))}
				/>
				<PootsyButton text={t("fetch_list_export")} onClick={this.fetchListDataExport} />
				<PootsyButton text={t("download_list_pdf")} onClick={this.fetchListForPDF} />
			</OptionBox>
		)
		let optionsBoxes = [boxOne]
		let contentTop = (
			<PootsyTextInput
				label={t("search_cleaners")}
				name="search"
				customClass="cleaners-sort"
				onChange={this.handleSearchChange}
			/>
		)
		let pageHeaderLeft = <h1 className="page-title cleaners">{t("cleaners")}</h1>
		let pageHeaderRight = (
			<NavLink to="/cleaners/new">
				<PootsyButton text={t("add_new_cleaner")} />
			</NavLink>
		)
		let links = [
			{ label: t("workers"), link: "/cleaners" },
			{ label: t("communication"), link: "/communication" },
		]
		let subNavLinks = links.map(entry => (
			<NavLink key={entry.label} to={entry.link} exact={true}>
				{entry.label}
			</NavLink>
		))
		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				headerRight={pageHeaderRight}
				subNavLinks={subNavLinks}
			>
				<ContentLayout
					customClass="cleaners"
					optionsBoxes={optionsBoxes}
					contentTop={contentTop}
				>
					{showWorkerLimitReachedModal && (
						<div className="cleaner-limit-reached-modal">
							{t("cleaner_limit_reached_desc")}
							<PootsyButton
								text={t("dismiss")}
								onClick={this.dismissWorkerLimitReachedModal}
							/>
						</div>
					)}
					<div className="cleaners-list">
						{this.props.cleaners.map(entry => (
							<WorkerLine key={entry.id} worker={entry} />
						))}
					</div>
					<Paginator
						name="pageQuery"
						currPage={page}
						totalPages={pagination.total_pages}
						goToPage={this.updateQuery}
					/>
				</ContentLayout>
			</PageLayout>
		)
	}
}

Workers.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	cleaners: state.redData.cleaners,
	workersComponent: state.redComponents.workersComponent,
	currUser: state.redData.currentUser,
	currentAffiliate: state.redData.currentAffiliate,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(Workers)
